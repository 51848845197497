import { watchEffect } from "vue";
import { promiseWithTimeLimit, setClientTimeout } from "@/client/utilities";

/**
 * When a route meta has requiresUser, will redirect guests based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresUserRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresUserRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "beforeEach",
    handler: async (to, from) => {
      let spinnerTimeout = utilities.setClientTimeout(() => {
        router.app.store.commit("ui/showGlobalSpinner");
      }, 200);

      await utilities.promiseWithTimeLimit(router.app.store.getters["user/initialFetchCompletePromise"], 1000);

      clearTimeout(spinnerTimeout);
      router.app.store.commit("ui/hideGlobalSpinner");
    },
  };
};
