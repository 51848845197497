<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">
        {{ translateTitleCase("userDashboard.conversations.pageTitle") }}
      </h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.conversations.introText") }}
      </p>

      <chat-view height="60vh" style="min-height:80vh;" ></chat-view>
    </template>
  </layout-user-dashboard>
</template>

<script>
import ChatView from "@/client/views/Chat.vue";
export default {
  components: {
    ChatView,
  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.conversations.pageTitle",
      subTitle: "userDashboard.conversations.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
      viewTitle: "userDashboard.conversations.pageTitle",
      viewExplain: "userDashboard.conversations.introText",
    },
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {};
  },
  computed: {
    userId() {
      return this.$store.getters["user/profile"].id;
    },
  },
  meta: "userDashboard.conversations.browserTitle",
};
</script>

<style scoped lang="scss"></style>
