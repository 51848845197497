<template>
  <login-view></login-view>
</template>

<script>
import LoginView from "@/client/views/Login.vue";

export default {
  components: {LoginView},
  layout: {
    component: "boxy",
    params: { backgroundImage: false }, //"core/images/boxy/default-background.jpg"
  },
  props: {},
  data: function () {
    return {
      traceExpanded: false,
    };
  },
  computed: {
    logoStyle() {
      if (!this.logoSrc) {
        return {};
      }

      let url = utilities.requireAsset(this.logoSrc);
      return {
        backgroundImage: "url('" + url + "')",
        "background-size": "contain",
        "background-position": "center center",
        "background-repeat": "no-repeat",
        height: "80px",
        width: "100%",
      };
    },
  },
  meta: "core.loginPage.pageTitle",
};
</script>

<style scoped lang="scss"></style>
