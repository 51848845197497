<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("ecommerce.vendors.displayTitle") }}</span>
    </template>

    <template #default>
      <custom-crud-list v-bind="listConfiguration">
        <template v-slot:cell-logoImageUrl="{item}">
          <img :src="item.logoImageUrl" style="max-height:50px;width:auto;max-width: 200px;" alt="">
        </template>
      </custom-crud-list>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";
export default {
  components: { CustomCrudList },
  layout: "adminDashboard",

  middleware: {
    requireSuperAdmin: {},
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.vendors.pageTitle",
  data: function () {
    return {
      listConfiguration: {
        subject: "vendor",
        listSrc: "ecommerce/vendor",
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: true, //bool, default false
        enableMassActions: true,
        editRoutePrefix: "admin-",
        createRoutePrefix: "admin-",
        deleteRoutePrefix: "ecommerce/",
        additionalListData: {
          simplified: false,
          translate: false,
        },
        fields: {
          // these are the table columns
          name: {
            itemKey: "name",
            label: "ecommerce.vendor.fields.name",
            filterable: true, // boolean, or object
            orderable: true,
          },
          logoImageUrl: {
            itemKey: "logoImageUrl",
            label: "ecommerce.vendor.fields.logoImageUrl",
          },
        },
        actions: {
          show: false,
          edit: true,
          delete: true,
        },
      },
      asyncData: {},
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
