// overload component options data with meta move options.meta to options.methods[accessorKey], so that plugins can access them on instance
function assignServerPrefetch(target) {
  if (typeof target.serverPrefetch === "undefined") {
    target["serverPrefetch"] = async function serverPrefetch() {
      return this.getSSRPrefetchPromise();
    };
  }

  return true;
  if (!target || !target.meta) return true;

  if (!target.methods) target.methods = {};

  //config.metaDataAccessorKey
  target.methods[config.metaDataAccessorKey] = function () {
    return target.meta;
  };
}

/**
 * Assign meta configuration from the component options, to the component data.
 * This allows nicer api, so that meta config is not nested inside data, but as a separate entry
 * on the component options
 *
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "beforeResolve",
    handler: async (to) => {
      try {
        assignServerPrefetch(to.matched[0].components.default);
      } catch (e) {}
    },
  };
};
