<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ tt("userDashboard.vendor.reviews.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ tu("userDashboard.vendor.reviews.introText") }}
      </p>

      <custom-crud-list v-bind="listConfiguration">
        <template #cell-title="{ item }">
          <span
            v-tooltip="{ content: item.title, translate: false }"
            class="text-overflow-ellipsis inline-block max-width-150 cursor-pointer">
            {{ item.title }}
          </span>
        </template>
        <template #cell-text="{ item }">
          <span
            v-tooltip="{ content: item.text, translate: false }"
            class="text-overflow-ellipsis inline-block max-width-150 cursor-pointer">
            {{ item.text }}
          </span>
        </template>
        <template #cell-productRating="{ item }">
          <form-input
            v-model="item.productRating"
            type="starRating"
            :icon-size="1.75"
            disabled="true"
            :auto-input-styling="false"
            theme-style="none">
          </form-input>
        </template>
        <template #cell-vendorRating="{ item }">
          <form-input
            v-model="item.vendorRating"
            type="starRating"
            :icon-size="1.75"
            disabled="true"
            :auto-input-styling="false"
            theme-style="none">
          </form-input>
        </template>
      </custom-crud-list>
    </template>
  </layout-user-dashboard>
</template>

<script>
import { computed } from "vue";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";

export default {
  components: {
    CustomCrudList,
  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.vendor.reviews.pageTitle",
      subTitle: "userDashboard.vendor.reviews.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
    },
  },
  middleware: {
    requireUser: {},
    requireVendor: {},
  },
  data: function () {
    return {
      listConfiguration: {
        subject: "review",
        listSrc: "entity/review",
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: false, //bool, default false
        enableMassActions: false,
        additionalListData: {
          simplified: false,
          translate: false,
        },
        constantFilters: {
          VendorId: computed(() => this.$store.getters["user/profile"]?.Vendor?.id),
        },
        fields: {
          title: {
            itemKey: "title",
            label: "ecommerce.reviews.fields.title",
          },
          text: {
            itemKey: "text",
            label: "ecommerce.reviews.fields.text",
          },
          productRating: {
            itemKey: "productRating",
            label: "ecommerce.reviews.fields.productRating",
          },
          vendorRating: {
            itemKey: "vendorRating",
            label: "ecommerce.reviews.fields.vendorRating",
          },
        },
        actions: false,
      },
    };
  },
  meta: "userDashboard.vendor.reviews.browserTitle",
};
</script>

<style scoped lang="scss"></style>
