import {computed} from 'vue';

export default {
  props: {
    limit: {
      type: Number,
      default: 25,
    }
  },
  data: function () {

    if (  !this.asyncOps  || ! this.socket) {
      warn('Notification center mixin: error - please import socket and asyncOps from asyncOperations in setup. They are required.', 2, {component: this});

    }
    return {
      notifications: false,
      socket: false,
      asyncData: {
        notifications: {
          target: "notification",
          data: {
            pagination: {
              start: 0,
              limit: computed( () => this.limit),
            }
            // add when implementing pagination
          },
          options: {
            dataMutator(input, result) {
              if (input.items) {
                return input.items;
              }

              return false;
            },
          },
        },
      },
    };
  },
  computed: {
    hasUnreadNotifications() {
      return this.unreadNotificationCount > 0;
    },
    unreadNotificationCount() {
      if (!this.notifications) {
        return 0;
      }

      try {
        return this.notifications.filter((item) => !item.isRead).length;
      } catch (e) {
        return 0;
      }
    },
  },
  async mounted() {
    this.socket = await this.socket;
    await this.socket.waitForUUID();
    this.socket.on("notification:created", this.socketNotificationCreatedHandler);
  },
  beforeUnmount() {
    this.socket.off("notification:created", this.socketNotificationCreatedHandler);
  },
  methods: {
    async playground() {
      let data = {};

      let res = await this.asyncOps.asyncCall("playground/use-case2", data, {
        method: "get",
      });

      console.log("auto create result", res, res.data);
      //   await this.refreshAsyncData();
    },
    async markNotificationAsRead(notification) {
      let res = await this.asyncOps.asyncCall(
        `notification/${notification.id}`,
        { isRead: true },
        { method: "patch" }
      );
      console.log("update result", res, res.data);
      await this.refreshAsyncData();
      console.log("data refreshed");
    },
    socketNotificationCreatedHandler(a, b) {
      console.log("new event", a, b);
      this.refreshAsyncData();
    },
  },
}
