<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-vendor' }">
    <template #title>
      <span>{{
        translateTitleCase("ecommerce.vendor.edit.displayTitle")
      }}</span>
    </template>

    <template #default>
      <custom-crud-edit v-bind="editConfiguration"></custom-crud-edit>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudEdit from "@/client/components/crud/customV1/Edit.vue";
import { computed } from "vue";
export default {
  components: { CustomCrudEdit },
  layout: "adminDashboard",

  middleware: {
    requireSuperAdmin: {},
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.vendor.edit.pageTitle",
  data: function () {
    return {
      editConfiguration: {
        subject: "vendor",
        routePrefix: "ecommerce/",
        submitUrl: computed(() => {
          return "ecommerce/vendor/" + this.id;
        }), // default: shine/[name]
        submitMethod: "patch", //default 'patch'
        cancelRedirect: { name: "admin-vendor" },
        successRedirect: { name: "admin-vendor" },
        itemId: this.id,
        itemMapper: async (data) => {
          return data;
        },
        formSource: computed(() => {
          return `entity/vendor/${this.id}/update`
        }),
        formConfig: {
          fieldWrapperClass: "",
          /*
          groups: {
            group1: {
              component: 'FormFieldSet',
              props: {
                title: 'shineAdmin.eventManager.eventDetailsFieldsetTitle',
                icon: 'calendar',
                maxFieldsPerRow: 4
              }
            },
            group2: {
              component: 'FormFieldSet',
              props: {
                title: 'shineAdmin.eventManager.eventMediaFieldsetTimeframe',
                icon: 'clock',
                maxFieldsPerRow: 3
              }
            },
            group3: {
              component: 'FormFieldSet',
              props: {
                title: 'shineAdmin.eventManager.eventMediaFieldsetTitle',
                icon: 'user',
                maxFieldsPerRow: 3
              }
            },
          },
          */
          fields: {


          },
          formHandling: {
            /*
            preflight: [
              {
                type   : 'validation',
                options: {}
              },
              {
                type   : 'submitDataMutation',
                options: {
                  handler: async ({data, next, quit}) => {
                    if (data.clientPhoneNumber && typeof data.clientPhoneNumber === 'object') {
                      data.clientPhoneNumber = String(data.clientPhoneNumber.prefix) + String(data.clientPhoneNumber.number);
                    }

                    return next();
                  }
                }
              },
            ]
            */
          },
        },
      },
      asyncData: {},
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
