<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ translateTitleCase("userDashboard.help.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.help.introText") }}
      </p>

      //
    </template>
  </layout-user-dashboard>
</template>

<script>

export default {
  components: {

  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.help.pageTitle",
      subTitle: "userDashboard.help.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
      viewTitle: "userDashboard.help.pageTitle",
      viewExplain: "userDashboard.help.introText",
    },
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {};
  },
  computed: {
    userId() {
      return this.$store.getters["user/profile"].id;
    },
  },
  meta: "userDashboard.help.browserTitle",
};
</script>

<style scoped lang="scss"></style>
