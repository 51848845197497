<template>
  <div class="crud-edit-external-wrapper">
    <div
      v-if="renderTopActions"
      class="admin-view-toolbar" style="margin-bottom: calc(var(--global-margin) * 2)">
      <div class="flex flex-between margin-l-bottom">
        <div class="start-side flex flex-between">
          <form-button
            icon="x"
            theme="gray"
            :show-slot="false"
            class="margin-s-inline-end"
            @click="redirectBack()"></form-button>
          <form-button
            icon="check"
            theme="lead"
            :show-slot="false"
            @click="submit"></form-button>
        </div>
        <div class="end-side"></div>
      </div>
    </div>

    <spinner v-if="!isReady" overlay="fixed"></spinner>

    <base-form
      v-show="isReady"
      ref="editForm"
      v-model="item"
      :validation-context="validationContext"
      :action="formAction"
      method="patch"
      :config="{ ...formConfig, context: 'edit' }"
      @form:submitSuccess="afterSubmit"
      @form:submitError="handleError"></base-form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { computed, reactive } from "vue";

export default {
  props: {
    validationContext: {
      type: Object,
      default: {},
    },
    renderTopActions: {
      type: Boolean,
      default: true,
    },
    apiGetItemUrl: {
      type: String,
      default: false,
    },
    formPath: {
      type: String,
      default: false,
    },
    formAction: {
      type: String,
      default: false,
    },
    successRedirect: {
      type: [ Object, Boolean, undefined ],
      default: undefined,
    },
    backRoute: {
      type: [Object, Boolean],
      default: false,
    },
  },
  emits: ["error"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      isReady: true,
      item: {},
      formConfig: {},
      asyncData: {
        item: {
          target: computed(() => this.apiGetItemUrl),
          data: computed(() => {}),
          options: {
            method: "get",
          },
        },
        formConfig: {
          target: this.formPath,
        },
      },
    };
  },
  computed: {},
  watch: {
    "asyncStatus.asyncDataReady": {
      handler(newVal) {
        if (!newVal) {
          return true;
        }

        const supportedKeys = Object.values(this.formConfig.fields).map(
          (field) => field.name
        );
        const newItem = { ...this.item };

        Object.keys(this.item).forEach((key) => {
          if (!supportedKeys.includes(key)) {
            delete newItem[key];
          }
        });

        this.item = newItem;
      },
    },
  },
  methods: {
    redirectBack() {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      } else {
        this.$router.back();
      }
    },
    afterSubmit(result) {
      // show notice, re-route to list
      this.$saffron.ui.notification("core.crud.updateSuccess", "success");

      console.log(this.successRedirect, typeof this.successRedirect);
      if (typeof this.successRedirect === 'undefined'){
        return true;
      }

      if (! this.successRedirect){
        this.$router.back();
      }

      if (this.successRedirect) {
        this.$router.push(this.successRedirect);
      }
    },
    submit: async function () {
      this.$refs.editForm.submit();
    },
    handleError({ component: form, data, result }) {
      this.$emit("error", { context: "submit", data: result });
    },
  },
};
</script>

<style scoped lang="scss"></style>
