import {
  createRouter,
  createWebHashHistory,
  createMemoryHistory,
  createWebHistory,
} from "vue-router";
import saffronMiddlewareBus from "@/client/router/saffronMiddlewareBus.js";
import asyncFactory from "@/client/extensions/modules/asyncComponentFactory.js";
import { watchEffect, nextTick } from "vue";

let appName = process.env.VUE_APP_APPLICATION_NAME;
let basePath = process.env.VUE_APP_BASE_RELATIVE_PATH___STRING;
let routeGroups = {};
let middleware = {};
let finalRoutes = [];
let pageRoutes = [];
let declaredRoutes = [];

let populateDeclaredRoutes = () => {
  // load all routes from modules
  let context = require.context("@/client/router/routes", true, /\.js/);
  context.keys().forEach((key) => {
    // name is the routes file name without extension
    let name = key.split("/").pop().replace(".js", "");

    routeGroups[name] = context(key).default;
  });

  // load all routes from app. route modules with same name as core will override
  context = require.context(
    "@/client/applications/",
    true,
    /^\.\/.*\/router\/routes.*\.js$/
  );
  context.keys().forEach((key) => {
    // filter only the modules for out application
    if (!key.startsWith("./" + appName)) {
      return;
    }
    // name is the routes file name without extension
    let name = key.split("/").pop().replace(".js", "");

    routeGroups[name] = context(key).default;
  });

  // route overrides
  context = require.context("@/", true, /\/overrides\/client\/router\/routes\/.*\.js/);
  context.keys().forEach((key) => {
    // name is the routes file name without extension
    let name = key.split("/").pop().replace(".js", "");

    routeGroups[name] = context(key).default;
  });

  // todo: route app overrides - not tested
  context = require.context(
    "@/",
    true,
    /overrides\/client\/applications\/.*\/router\/routes.*\.js$/
  );

  context.keys().forEach((key) => {
    // filter only the modules for out application
    if (!key.startsWith("./overrides/client/applications/" + appName)) {
      return;
    }

    // name is the routes file name without extension
    let name = key.split("/").pop().replace(".js", "");

    routeGroups[name] = context(key).default;
  });

  // merge the routes
  for (const [key, routesArray] of Object.entries(routeGroups)) {
    declaredRoutes = declaredRoutes.concat(routesArray);
  }
};

let populateMiddleware = () => {
  let context;
  // middleware - from core
  context = require.context("@/client/router/middleware", true, /\.js/);
  context.keys().forEach((key) => {
    // name is the routes file name without extension
    let name = key.split("/").pop().replace(".js", "");

    middleware[name] = context(key).default;
  });

  // middleware - from override
  context = require.context(
    "@/",
    true,
    /\/overrides\/client\/router\/middleware\/.*\.js/
  );

  context.keys().forEach((key) => {
    // name is the routes file name without extension
    let name = key.split("/").pop().replace(".js", "");
    middleware[name] = context(key).default;
  });

  // middleware - from app
  context = require.context(
    "@/client/applications/",
    true,
    /^\.\/.*\/router\/middleware.*\.js$/
  );
  context.keys().forEach((key) => {
    // filter only the modules for out application
    if (!key.startsWith("./" + appName)) {
      return;
    }

    let name = key.split("/").pop().replace(".js", "");

    middleware[name] = context(key).default;
  });

  // middleware - from app override
  context = require.context(
    "@/",
    true,
    /overrides\/client\/applications\/.*\/router\/middleware.*\.js$/
  );

  context.keys().forEach((key) => {
    // filter only the modules for out application
    if (!key.startsWith("./overrides/client/applications/" + appName)) {
      return;
    }

    let name = key.split("/").pop().replace(".js", "");

    middleware[name] = context(key).default;
  });
};

// TODO: decide on standard for a 404 page, and general error page
let populatePageRoutes = () => {
  let routes = {};
  let context;

  let buildRouteDefinitionFromFileName = (
    key,
    componentPathPrefix,
    pathPrefix = "./"
  ) => {
    let getPath = (key) => {
      let arrParts = key.replace("./", "").replace(".vue", "").split("/");
      let path = [];

      arrParts.forEach((part, index) => {
        let cleanedPart = part;
        let isLastPart = index === arrParts.length - 1;

        // this path part means the component inside it should have the path up until it, not including "index" in the end
        if (part === "index" && isLastPart) {
          return true;
        }

        // this part represents an optional param
        let isParamPath = part.startsWith("[") && part.endsWith("]");
        if (!isParamPath) {
          path.push(cleanedPart);
          return true;
        }

        // last params are required. params that start with [- are not, unless they are last
        // param sthat start with [ without a "-" are required
        let isRequiredParam = isLastPart || !part.startsWith("[-");
        // if part is [...] we need to remove first and last, and if it is [!...] 2 first and the last

        let cleanedPath = part
          .replaceAll("[-", "")
          .replaceAll("[", "")
          .replaceAll("]", "");

        if (!isRequiredParam) {
          cleanedPath = cleanedPath + "?";
        }
        path.push(":" + cleanedPath);
      });

      return "/" + path.join("/");
    };

    let getName = (key) => {
      let arrParts = key.replace("./", "").replace(".vue", "").split("/");
      let path = [];

      let strName;

      // noinspection DuplicatedCode -
      arrParts.forEach((part, index) => {
        let isLastPart = index === arrParts.length - 1;
        let cleanedPart = part;

        // path ending in an index.vue should not have the "index" apended to their name
        if (part === "index" && isLastPart) {
          return true;
        }

        if (part.startsWith("[") && part.endsWith("]")) {
          cleanedPart = part.substring(1, part.length - 1);
        }
        if (part.startsWith("[-") && part.endsWith("]")) {
          cleanedPart = part.substring(2, part.length - 1);
        }
        path.push(cleanedPart);
      });

      if (path.length !== 0) {
        strName = path.join("-");
      } else {
        strName = "index";
      }

      return strName;
    };

    let isIgnore = (key) => {
      let isIgnore = false;
      let arrParts = key.replace("./", "").replace(".vue", "").split("/");
      let path = [];

      arrParts.forEach((part, index) => {
        let cleanedPart = part;
        let isLastPart = index === arrParts.length - 1;

        // skip "index" parts
        if (part === "index" && isLastPart) {
          return true;
        }

        // skip params parts
        if (part.startsWith("[") && part.endsWith("]")) {
          return true;
        }

        // this part represents a "regular" path part - do check
        if (cleanedPart.startsWith("_")) {
          isIgnore = true;
          return false;
        }
      });

      return isIgnore;
    };

    let getParentNames = (name) => {
      let parents = name.split("-");
      let parentNames = [];
      let previous = false;

      // skip the last segment (we are not our own parent)
      parents.splice(0, parents.length - 1).map((name) => {
        if (!previous) {
          parentNames.push(name);
        } else {
          parentNames.push(previous + "-" + name);
        }

        previous = previous ? previous + "-" + name : name;
      });

      return parentNames;
    };

    let cleanKey = key.replace(pathPrefix, "");

    let result = {
      path: getPath(cleanKey),
      name: getName(cleanKey),
      component: asyncFactory(componentPathPrefix + "/" + cleanKey),
      meta: {
        isPage: true,
        saffronPageParents: getParentNames(getName(cleanKey)),
      },
      props: true,
      isIgnore: isIgnore(key),
    };

    return result;
  };

  // core pages
  context = require.context("@/", true, /\/client\/pages\/.*\.vue/);
  context.keys().forEach((key) => {
    if (!key.startsWith("./client/pages")) {
      return true;
    }

    let definition = buildRouteDefinitionFromFileName(key, "pages", "./client/pages/");

    routes[definition.name] = definition;
  });

  // core pages  overrides
  context = require.context("@/", true, /\/overrides\/client\/pages\/.*\.vue/);
  context.keys().forEach((key) => {
    let definition = buildRouteDefinitionFromFileName(
      key,
      "pages",
      "./overrides/client/pages/"
    );
    routes[definition.name] = definition;
  });

  // app pages
  context = require.context("@/client/applications/", true, /^\.\/.*\/pages.*\.vue$/);
  context.keys().forEach((key) => {
    // filter only the modules for out application
    if (!key.startsWith("./" + appName)) {
      return;
    }

    // name is the routes file name without extension
    let definition = buildRouteDefinitionFromFileName(
      key,
      "applications/" + appName + "/pages",
      "./" + appName + "/pages/"
    );

    routes[definition.name] = definition;
  });

  // app pages overrides
  context = require.context(
    "@/",
    true,
    /overrides\/client\/applications\/.*\/pages\/.*\.vue$/
  );
  context.keys().forEach((key) => {
    // filter only the modules for out application
    if (!key.startsWith("./overrides/client/applications/" + appName)) {
      return;
    }

    // name is the routes file name without extension
    let definition = buildRouteDefinitionFromFileName(
      key,
      "applications/" + appName + "/pages",
      "./overrides/client/applications/" + appName + "/pages/"
    );

    routes[definition.name] = definition;
  });

  // make an array of derived routes, ignoring those who wish to be ignored
  for (const [name, conf] of Object.entries(routes)) {
    if (conf.isIgnore) {
      continue;
    }

    pageRoutes.push(conf);
  }
};

let getHistory = () => {
  if (utilities.isSSR()) {
    //on the server, we must use memory history
    return createMemoryHistory();
  }

  if (!utilities.isSSR() && config.useSSR) {
    // when we have ssr, but this render is on client - we need web history
    return createWebHistory();
  }

  if (config.router.preferredRouterHistory === "webHash") {
    // without SSR, we can use our config - webHash or web
    return createWebHashHistory();
  }

  return createWebHistory();
};

populateDeclaredRoutes();
populateMiddleware();
populatePageRoutes();

let absoluteRootRoute = {
  path: "/",
  name: "absoluteRoot",
  component: asyncFactory("views/RouterView.vue"),
};

let rootLocaleRoute = {
  path: "/:localeSlug",
  name: "rootLocale",
  component: asyncFactory("views/RouterView.vue"),
  // props() overriden on create, as it needs store access
};

if (config.router.useI18n) {
  // add all the routes to the rootLocaleRoute. remove leading slashes.
  rootLocaleRoute.children = [...pageRoutes, ...declaredRoutes].map((route) => {
    if (route.path.startsWith("/")) {
      route.path = route.path.slice(1);
    }
    return route;
  });
  finalRoutes = [absoluteRootRoute, rootLocaleRoute];
} else {
  finalRoutes = [...declaredRoutes, ...pageRoutes];
}

const setRouteToMatchLocale = async (locale, router) => {
  // if router isnt given, it is probably not set.
  // we are powerless to do anything, and also, shouldn't
  if (!router) {
    return;
  }

  let currentRoute = router.currentRoute.value;
  let currentParams = currentRoute.params;
  let hasParams = currentParams && typeof currentParams === "object";
  if (!hasParams) {
    currentParams = {};
  }

  let routeLocaleSlug = currentParams?.localeSlug;
  // on initial load, locale may still be undefined for a moment. lets wait for that
  if (typeof routeLocaleSlug === "undefined") {
    await utilities.wait(100);
  }

  if (routeLocaleSlug === locale) {
    return true;
  }

  // the locale we got differes from router. adjust router.
  router.push({
    name: router.currentRoute.name,
    params: { ...currentParams, localeSlug: locale },
  });
};

const getLocaleMiddleware = (app) => {
  return async (to, from) => {
    let targetLocale = to.params.localeSlug;

    let getRedirectToCurrentWithLocale = (to) => {
      let name = to.name ? to.name : "localeRoot";
      let userLocale = app.store.getters["locale/slug"];
      if (!userLocale) {
        userLocale = config.locale.defaultLocale;
      }

      return { path: userLocale + to.fullPath };
    };

    let isLocaleLike = (str) => {
      return /[a-z]+-[A-Z]/i.test(str);
    };

    // the segment is not a valid locale
    if (!config.locale.availableLocales.includes(targetLocale)) {
      // first segment doesnt seem like a locale.
      // //so redirect to the url, but with locale prefix
      if (!isLocaleLike(targetLocale)) {
        return getRedirectToCurrentWithLocale(to);
      }

      // invalid locale, most likely. thats a 404.
      return { name: 404 };
    }

    app.store.commit("locale/slug", targetLocale);

    // if (app.store.getters["locale/slug"] !== targetLocale) {
    // app.store.commit("locale/slug", targetLocale);
    // }

    await nextTick();
    await nextTick();

    return true;
  };
};

const getRootLocaleMiddleware = (app) => {
  return async (to, from) => {
    let targetLocale = app.store.getters["locale/slug"];

    return { name: "index", params: { localeSlug: targetLocale } };
  };
};

let routerFactory = (app) => {
  let router;
  let routerRoutes;

  if (config.router.useI18n) {
    (rootLocaleRoute.props = (route) => {
      let appLocale = app.store.getters["locale/slug"];
      return {
        ...route.params,
        localeSlug: route.params.localeSlug || appLocale,
      };
    }),
      // add all the routes to the rootLocaleRoute. remove leading slashes.
      (rootLocaleRoute.children = [...pageRoutes, ...declaredRoutes].map((route) => {
        if (route.path.startsWith("/")) {
          route.path = route.path.slice(1);
        }
        return route;
      }));
    routerRoutes = [absoluteRootRoute, rootLocaleRoute];
    rootLocaleRoute.beforeEnter = getLocaleMiddleware(app);
    absoluteRootRoute.beforeEnter = getRootLocaleMiddleware(app);
    watchEffect(() => {
      setRouteToMatchLocale(app.store.getters["locale/slug"], router);
    });
  } else {
    routerRoutes = [...declaredRoutes, ...pageRoutes];
  }

  // overload base relative path, if applicable
  if (basePath && basePath !== '') {
    routerRoutes = routerRoutes.map(item => {
      item.path = basePath + item.path;
      return item;
    });
  }

  // overload the localeRoot if available
  router = createRouter({
    history: getHistory(),
    routes: routerRoutes,
    linkActiveClass: config.router.linkActiveClass,
    async scrollBehavior(to, from, savedPosition) {
      let standardReturn = () => {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      };

      if (utilities.isSSR()) {
        return standardReturn();
      }
      // make sure rendering takes place
      await utilities.wait(10);
      // hash handling
      let el = to.hash ? document.getElementById(to.hash.slice(1)) : false;
      if (!el) {
        return standardReturn();
      }
      // manually try to scroll to the thing after an insignificant amount
      await utilities.wait(50);
      let scrollPosition = window.scrollY + el.getBoundingClientRect().top;
      window.scrollTo(0, scrollPosition);

      // wait a bit for dom to take shape, then resolve
      await utilities.wait(200);
      scrollPosition = window.scrollY + el.getBoundingClientRect().top;
      return { top: scrollPosition };
    },
  });

  router["app"] = app;
  router["isStoreSet"] = false;

  // set global middleware
  for (const [name, factory] of Object.entries(middleware)) {
    let middleware = factory(router);
    if (middleware.routerMethod !== "saffronBus") {
      router[middleware.routerMethod](middleware.handler);
    }
  }

  // saffron middleware bus, allows defining middle ware on components with the middleware option
  router.beforeResolve(saffronMiddlewareBus(router, middleware));

  return router;
};

export default routerFactory;
