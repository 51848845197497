import routerFactory from "@/client/router";
import _ from "lodash/object";

import { reactive, computed, getCurrentInstance, watchEffect, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

/**
 * Please use this with useRouter() for full functionallity
 * Provides properties about layout, to allow to use a component from Layouts to wrap the content
 * @param props
 * @param router
 * @returns {{finalLayoutRaw: ComputedRef<any> | WritableComputedRef<any>, layoutParams: ComputedRef<*> | WritableComputedRef<any>, finalLayout: ComputedRef<string> | WritableComputedRef<any>}}
 */
export default (props, router) => {
  let instance = getCurrentInstance();
  let route = useRoute();
  let currentRoute = ref(route);

  let layoutState = reactive({
    layout: props.layout || "none",
    forcedLayout: props.forcedLayout || false,
    layoutParams: props.layoutParams || {},
  });

  const finalLayout = computed(() => {
    let result =
      "Layout" +
      finalLayoutRaw.value.charAt(0).toUpperCase() +
      finalLayoutRaw.value.slice(1);
    return result;
  });

  let getResolvedComponent = (targetRoute) => {
    if (!targetRoute) {
      return false;
    }

    if (!targetRoute.value) {
      return false;
    }

    if (!targetRoute.value.matched) {
      return false;
    }

    if (!targetRoute.value.matched[0]) {
      return false;
    }

    let targetIndex = targetRoute.value.matched.length - 1;

    if (
      targetRoute.value.matched[targetIndex].components &&
      targetRoute.value.matched[targetIndex].components["default"]
    ) {
      return targetRoute.value.matched[targetIndex].components["default"];
    }

    return false;
  };

  let getResolvedComponentLayoutDefinition = (targetRoute) => {
    let componentOptions = getResolvedComponent(targetRoute);

    if (!componentOptions) {
      return false;
    }

    if (!componentOptions.layout) {
      return null;
    }

    // support string only definition
    if (typeof componentOptions.layout === "string") {
      return {
        component: componentOptions.layout,
      };
    }

    if (typeof componentOptions.layout === "object") {
      return componentOptions.layout;
    }

    return false;
  };

  const finalLayoutRaw = computed(() => {
    // if component forces a layout use that
    if (typeof layoutState.forcedLayout === "string") {
      return layoutState.forcedLayout;
    }

    // maybe we can find a layout from the component itself, usually when it is a page
    let componentLayoutDefinition = getResolvedComponentLayoutDefinition(currentRoute);

    if (componentLayoutDefinition) {
      if (componentLayoutDefinition.component.startsWith("Layout")) {
        // support case when consumer uses 'Layout' prefix
        return componentLayoutDefinition.component.replace("Layout", "");
      } else {
        return componentLayoutDefinition.component;
      }
    }

    // no? maybe we have a layout defined in route
    if (currentRoute && typeof currentRoute.value.meta.layout === "string") {
      return currentRoute.value.meta.layout;
    }

    // default layout
    return layoutState.layout;
  });

  // based on layout params in route, provide final layout params
  const layoutParams = computed(() => {
    let params = layoutState.layoutParams;

    // merge from component definition
    let componentLayoutConf = getResolvedComponentLayoutDefinition(currentRoute);

    if (
      componentLayoutConf &&
      componentLayoutConf.params &&
      typeof componentLayoutConf.params === "object"
    ) {
      params = componentLayoutConf.params; //_.merge(params, componentLayoutConf.params);
    }

    // merge from currentRoute meta definition
    if (currentRoute && typeof currentRoute.value.meta.layoutParams === "object") {
      params = currentRoute.value.meta.layoutParams; //_.merge(params, currentRoute.value.meta.layoutParams);
    }

    return params;
  });

  return { finalLayout, finalLayoutRaw, layoutParams };
};
