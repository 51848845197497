<template>

  <div>
    <h1>{{ t("ecommerce.checkout.orderComplete.browserTitle") }}</h1>
    <form-button @click="createOrder">Create Order</form-button>
    // todo: get order UUID by state or URL, then display it
    <br>

  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { reactive } from "vue";
import useCarts from "@/client/extensions/composition/useCarts.js";

export default {
  layout: "default",
  props: {
    cartId: {
      type: [String, Array],
      default: false,
    },
  },
  components: { JsonTreeView },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);

    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data: function () {
    return {
      cart: false,
    };
  },
  computed: {

  },
  meta: "ecommerce.checkout.orderComplete.browserTitle",
  async created() {

  },
  methods: {

  },
  mounted() {},
};
</script>

<style scoped lang="scss"></style>
