<template>
  <div
    class="page shine main-page padding-l scan-page bg-lead"
    style="min-height: 100vh">
    <div class="container padding-l bg-bg-4">
      <card> TEMP HOME PAGE</card>
    </div>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import { useStore } from "vuex";

export default {
  components: {},
  layout: "default",
  middleware: {},
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(
      props,
      useStore()
    );
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "sf.defaultPage.pageTitle",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
