import asyncFactory from "@/client/extensions/modules/asyncComponentFactory.js";

export default [
  {
    path: "/admin/config",
    component: asyncFactory("views/admin/config/List.vue"),
    name: "admin-config",
    meta: {
      layout: "adminDashboard",
      requiresAdmin: true,
    },
  },
  {
    path: "/admin/config/edit/:entryKey",
    component: asyncFactory("views/admin/config/Edit.vue"),
    name: "admin-config-edit",
    props: true,
    meta: {
      layout: "adminDashboard",
      requiresAdmin: true,
    },
  },
];
