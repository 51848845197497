<template>
  <block class="crud-create">
    <div
      class="action-bar flex-between flex-wrap padding-m-vertical margin-xl-bottom">
      <div
        class="top-actions flex flex-middle gap-l animate__fadeIn animate--fast">
        <form-button
          theme="gray-5"
          icon="chevron-inline-start"
          :loading="formDisabled"
          :disabled="formDisabled"
          @click="doCancel"
          >{{ translate("core.crudV1.cancel") }}</form-button
        >
        <form-button
          v-if="duplicateUrl"
          theme="alt"
          icon="copy-page"
          :loading="formDisabled"
          :disabled="formDisabled"
          @click="duplicate"
        >{{ translate("core.crudV1.duplicate") }}</form-button
        >
        <form-button
          theme="success"
          icon="check"
          :loading="formDisabled"
          :disabled="formDisabled"
          @click="doSubmit"
          >{{ translate("core.crudV1.save") }}</form-button
        >
      </div>
    </div>
    <div class="form-container">
      <base-form
        :validation-context="{id:itemId}"
        v-bind="finalFormConfig"
        ref="form"
        v-model="itemData"

        :disabled="formDisabled"
        @update:modelValue="emitItemUpdated"
        @form:submitSuccess="handleSuccess"
        @form:submitProcessStart="formDisabled = true"
        @form:submitProcessEnd="formDisabled = false"></base-form>
    </div>
  </block>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import { computed } from "vue";
import _ from "lodash/object";

export default {
  components: {},
  props: {
    subject: {
      type: String,
      default: "generic",
    },
    submitUrl: {
      type: [String, Boolean],
      default: false,
    },
    duplicateUrl: {
      type: [String, Boolean],
      default: false,
    },
    duplicationSanitizer: {
      type: [Boolean, Function],
      default: false,
    },
    submitMethod: {
      type: String,
      default: "patch",
    },
    cancelRedirect: {
      type: [String, Boolean, Function],
      default: false,
    },
    successRedirect: {
      type: [String, Boolean, Function],
      default: false,
    },
    formConfig: {
      type: [String, Object, Boolean],
      default: false,
    },
    formSource: {
      type: [String, Boolean],
      default: false,
    },
    formSourceData: {
      type: Object,
      default: {},
    },
    formSourceMutator: {
      type: Function,
      default: (data) => data,
    },
    routePrefix: {
      type: [String, Boolean],
      default: "/",
    },
    itemSrc: {
      type: [String, Boolean, Object, Function],
      default: false,
    },
    extraRequestData: {},
    extraSubmitData: {},
    itemId: {
      type: [String, Boolean, Number],
      default: false,
    },
    /**
     * Parse incoming item data
     */
    itemMapper: {
      type: [Function, String, Boolean],
      default: false,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      defaultRoutePrefix: "/",
      itemData: {},
      formLoaded: false,
      remoteFormConfig: {},
      formDisabled: false,
    };
  },
  emits: ["form:dateUpdated", "item:updated"],
  computed: {
    finalFormConfig() {
      let conf;

      if (!this.formLoaded) {
        return {};
      }

      conf = { ...this.remoteFormConfig };

      if (this.formConfig && typeof this.formConfig === "object") {
        conf = _.merge(conf, this.formConfig);
      }

      conf.method = this.submitMethod;
      conf.action = this.submitUrl
        ? this.submitUrl
        : this.defaultRoutePrefix + this.subject;
      // append a button, if no buttons are set. our parent can set buttons: false to block this
      if (!conf.buttons) {
        conf.buttons = {
          submit: {
            text: "core.crudV1.save",
            type: "submit",
            icon: "check",
            theme: "success",
            fullWidth: true,
          },
        };
      }
      return conf;
    },
  },
  watch: {
    formSource: {
      handler(newVal) {
        if (newVal) {
          this.formLoaded = false;
          this.loadFromBySource(newVal);
        } else {
          // no source - using parent rules
          this.formLoaded = true;
        }
      },
      immediate: true,
    },
    itemSource: {
      deep: true,
      handler: "setItem",
    },
    itemData: {
      handler(newVal, oldVal) {
        this.$emit("item:updated", { itemData: newVal, oldData: oldVal });
      },
      deep: true,
    },
  },
  created() {
    this.setItem();
  },
  methods: {
    async loadFromBySource(source) {
      try {
        let formRequestResult = await this.asyncOps.asyncCall(
          this.formSource,
          this.formSourceData,
          { method: "get" }
        );
        if (formRequestResult.isError) {
          this.$s.ui.notification("core.errorGeneric", "error");
        }

        this.remoteFormConfig = this.formSourceMutator(formRequestResult.data);
        this.formLoaded = true;
      } catch (e) {
        this.$s.ui.notification("core.errorGeneric", "error");
      }
    },
    doNavigation(arg) {
      if (typeof arg === "function") {
        return this.cancel();
      }

      if (arg && typeof arg === "object") {
        this.$router.push(arg);
      }

      if (typeof arg === "string") {
        this.$router.push({ name: arg });
      }

      // default to back
      this.$router.back();
    },
    handleSuccess() {
      this.formDisabled = false;
      this.$s.ui.notification(
        "core.crudV1.updateSuccessNotification",
        "success"
      );
      this.doNavigation(this.success);
    },
    doCancel() {
      if (this.formDisabled) {
        return;
      }
      this.doNavigation(this.cancel);
    },
    submit() {
      this.$refs.form.submit();
    },
    doSubmit() {
      if (this.formDisabled) {
        return;
      }
      return this.submit();
    },
    async setItem() {
      if (this.itemSrc && typeof this.itemSrc === "object") {
        this.itemData = this.itemSrc;
        return;
      }

      let finalSrc = this.itemSrc;

      if (!this.itemSrc) {
        finalSrc = this.routePrefix + this.subject + "/" + this.itemId;
      }

      if (typeof this.itemSrc === "function") {
        finalSrc = this.itemSrc();
      }

      let result = await this.asyncOps.asyncCall(finalSrc, {
        ...this.extraRequestData,
      });

      if (result.isError) {
        this.formDisabled = true;
        this.$s.ui.notification(
          "core.crudV1.loadEditItemFailNotification",
          "error"
        );
        return;
      }

      if (this.itemMapper) {
        if (this.itemMapper === "raw") {
          this.itemData = result.data;
        } else {
          this.itemData = await this.itemMapper(result.data.item);
        }
      } else {
        this.itemData = result.data.item;
      }
      this.formDisabled = false;
    },
    emitItemUpdated(formData) {
      this.$emit("form:dataUpdated", formData);
    },
    async duplicate() {
      let duplicateData = this.itemData;

      if (duplicateData.id) {
        delete duplicateData.id;
      }

      if (duplicateData.uuid) {
        delete duplicateData.uuid;
      }

      if (this.duplicationSanitizer) {
        duplicateData = await this.duplicationSanitizer(duplicateData);
      }

      let copyResult = await this.asyncOps.asyncCall(this.duplicateUrl, duplicateData, {method:"post"});

      if (copyResult.hasError) {
        this.$s.ui.notification('core.crudV1.duplicationError','error');
        return;
      }
      this.$s.ui.notification(
        "core.crudV1.duplicateSuccessNotification",
        "success"
      );
      this.doNavigation(this.success);

    },
  },
};
</script>

<style scoped lang="scss"></style>
