import asyncOperations from "@/client/extensions/composition/asyncOperations";
import _ from "lodash";
import { ref, reactive } from "vue";

export default (props, store = undefined) => {
  const asyncOps = asyncOperations(props, store).asyncOps;

  const isProductFormReady = ref(true);

  const productFormConfig = ref({});

  const allTypes = [...config.ecommerce.productTypes.activeProductTypes];

  const currentFormProductTypes = ref([]);

  const doesProductFormMatchTypes = (types) => {
    let targetTypes = Array.isArray(types) ? types : [];

    // different length = mismatch
    if (targetTypes.length !== currentFormProductTypes.value.length) {
      return false;
    }

    // check that our types include all the requested types
    return targetTypes.every((v) => currentFormProductTypes.value.includes(v));
  };

  const adjustFormByProductTypes = async (types = [], force) => {
    if (!force && doesProductFormMatchTypes(types)) {
      return true;
    }

    isProductFormReady.value = false;
    let finalForm = {
      fields: {
        typeList: {
          name: "typeList",
          label: "ecommerce.product.fields.productTypes",
          type: "select2",
          multiple: true,
          list: allTypes,
          validation: {
            required: {},
          },
          group: "top",
        },
      },
      groups: {
        top: {
          component: "block",
          props: {
            class: "margin-2xl-bottom",
          },
        },
        base: {
          component: "CollapsableFieldSet",
          props: {
            title: "ecommerce.product.fieldsets.base.title",
            initiallyOpened: false,
          },
        },
        DefaultProductTypeGroup: {
          component: "CollapsableFieldSet",
          props: {
            title: "ecommerce.product.fieldsets.defaultType.title",
            initiallyOpened: false,
          },
        },
      },
    };
    // load base form
    let baseForm = await loadFormBySource("entity/product/create");
    if (!baseForm) {
      console.log("error getting type form");
    }

    for (const [key, conf] of Object.entries(baseForm.fields)) {
      finalForm.fields[key] = { ...conf, group: "base" };
    }

    for (const type of types) {
      let fullTypeName = _.upperFirst(type) + "ProductType";
      // create group
      finalForm.groups[fullTypeName] = {
        component: "CollapsableFieldSet",
        props: {
          title: `ecommerce.product.fieldsets.${type}Type.title`,
          initiallyOpened: false,
        },
      };

      // get form
      let typeForm = await loadFormBySource("entity/" + fullTypeName + "/create");
      if (!typeForm) {
        console.log("error getting type form");
      }
      // assign modified fields (with model target)
      for (const [key, conf] of Object.entries(typeForm.fields)) {
        finalForm.fields["type-" + type + key] = {
          ...conf,
          group: fullTypeName,
          modelTarget: fullTypeName + "." + conf.name,
        };
      }
    }

    // merge the forms together
    productFormConfig.value = finalForm;
    isProductFormReady.value = true;
    currentFormProductTypes.value = [...types];
  };

  const loadFormBySource = async (source, data = {}) => {
    let formRequestResult;
    try {
      formRequestResult = await asyncOps.asyncCall(source, data, { method: "get" });
    } catch (e) {
      return false;
    }

    if (formRequestResult.isError) {
      return false;
    }

    return formRequestResult.data;
  };

  return {
    isProductFormReady,
    adjustFormByProductTypes,
    productFormConfig,
    currentFormProductTypes,
    productForm: reactive({
      isReady: isProductFormReady,
      adjustTypes: adjustFormByProductTypes,
      config: productFormConfig,
      currentTypes: currentFormProductTypes,
    }),
  };
};
