<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ translateTitleCase("userDashboard.profile.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.profile.introText") }}
      </p>

      <crud-edit
        :api-get-item-url="'entity/user/' + userId"
        :form-path="'entity/user/' + userId + '/update'"
        :form-action="'entity/user/' + userId"
        :render-top-actions="false"></crud-edit>
    </template>
  </layout-user-dashboard>
</template>

<script>
import CrudEdit from "@/client/components/crud/Edit.vue";
export default {
  components: {
    CrudEdit,
  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.profile.pageTitle",
      subTitle: "userDashboard.profile.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
      viewTitle: "userDashboard.profile.pageTitle",
      viewExplain: "userDashboard.profile.introText",
    },
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {};
  },
  computed: {
    userId() {
      return this.$store.getters["user/profile"].id;
    },
  },
  meta: "userDashboard.profile.browserTitle",
};
</script>

<style scoped lang="scss"></style>
