<template>
  <div class="timeline-item" :class="computedItemClass">
    <div
      class="timeline-item__bullet"
      :class="{ 'timeline-item__bullet--no-content': !hasBulletContent }">
      <slot name="bullet">
        <icon v-if="icon" :icon="icon" :set="iconSet"></icon>
        <div v-else class="timeline-item__bullet-center"></div>
      </slot>

    </div>
    <div class="timeline-item__content">
      <slot name="title">
        <h6 class="timeline-item__title" v-if="title">
          <slot name="title-text">{{ title }}</slot>
        </h6>
      </slot>
      <div class="timeline-item__timestamp " v-if="dateTime">
        {{translateDate(dateTime, dateTimeFormat)}}
      </div>
      <slot name="content">
        <div class="timeline-item__main-content">
          <p>
            <slot></slot>
          </p>
        </div>
      </slot>




    </div>
  </div>
</template>

<script>
export default {
  inject: ["timelineActive", "timelineLineStyle"],
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
    iconSet: {
      type: String,
      default: config.style.defaultIconSet,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    active: {
      type: [Boolean],
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    lineStyle: {
      type: [String, Boolean],
      default: false,
    },
    defaultLineStyle: {
      type: String,
      default: "solid",
    },
    dateTime: {
      type: [String, Object, Boolean],
      default: false,
    },
    dateTimeFormat: {
      type: [String],
      default: "timeAgo",
    }
  },
  data: function () {
    return {};
  },
  computed: {
    hasCustomBulletContent() {
      if (this.$slots['bullet']) {
        return true;
      }

      return false;
    },
    hasBulletContent() {
      if (this.icon) {
        return true;
      }

      if (this.$slots['bullet']) {
        return true;
      }

      return false;
    },
    computedItemClass() {
      let res = [];

      // line style
      res.push(`line-${this.finalLineStyle}`);
      // active state
      if (this.isActive) {
        res.push("active");
      }
      return res;
    },
    isActive() {
      if (this.isActive) {
        return true;
      }
      if (!this.timelineActive || typeof this.timelineActive !== "number") {
        return false;
      }

      return this.index <= this.timelineActive;
    },
    finalLineStyle() {
      if (this.lineStyle) {
        return this.lineStyle;
      }

      if (this.timelineLineStyle) {
        return this.timelineLineStyle;
      }

      return this.defaultLineStyle;
    },
  },
};
</script>

<style scoped lang="scss"></style>
