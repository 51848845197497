import { watchEffect, computed } from "vue";

const getSpinnerOptions = (conf) => {
  let showSpinner = config.router.middleware.saffronBus.showSpinner;
  let spinnerDelay = config.router.middleware.saffronBus.spinnerDelay;
  let spinnerText = config.router.middleware.saffronBus.spinnerText;

  if (conf?.spinner === false) {
    showSpinner = false;
  }

  if (typeof conf?.spinner === "string") {
    spinnerText = conf?.spinner;
  }

  if (typeof conf?.spinnerDelay === "number") {
    spinnerDelay = conf?.spinnerDelay;
  }

  return { showSpinner, spinnerDelay, spinnerText };
};
const runBus = async (to, from, middleware = [], index = 0, router) => {
  let current = middleware[index];
  let middlewareSaffronConfig = current?.context?.saffron ?? {};
  let { showSpinner, spinnerDelay, spinnerText } = getSpinnerOptions(
    middlewareSaffronConfig
  );
  let spinnerTimeout = 0;

  //  end condition: all middleware was executed/invalid index
  if (index > middleware.length - 1) {
    return true;
  }

  // spinner behaviour
  if (showSpinner) {
    spinnerTimeout = setTimeout(() => {
      router.app.store.commit(
        "ui/showGlobalSpinner",
        spinnerText ? { text: spinnerText, active: true } : true
      );
    }, spinnerDelay);
  }

  // run current middleware
  let middlewareResult = await current.handler(to, from, current.context);

  // spinner behaviour
  if (showSpinner) {
    clearTimeout(spinnerTimeout);
    router.app.store.commit("ui/showGlobalSpinner", false);
  }

  if (middlewareResult === false) {
    return false;
  }

  // vue router stop condition: router object
  if (middlewareResult && typeof middlewareResult === "object") {
    return middlewareResult;
  }

  // recursion: next middleware
  return await runBus(to, from, middleware, (index = index + 1), router);
};

export default (router, allMiddleware) => {
  return async (to, from) => {
    let requestedMiddleware = {};
    try {
      requestedMiddleware = to.matched[to.matched.length - 1].components.default.middleware || {};
      //console.log(to.matched[0].components.default);
    } catch (e) {
      return true;
    }

    if (!requestedMiddleware || Object.entries(requestedMiddleware).length < 1) {
      return true;
    }

    let finalMiddleware = [];

    // lets support different argument types
    function populateMiddlewareByObject(middlewareObject) {
      if (middlewareObject === "null" || typeof middlewareObject !== "object") {
        return;
      }

      for (const [name, context] of Object.entries(middlewareObject)) {
        if (!allMiddleware.hasOwnProperty(name)) {
          utilities.debug(
            "warning: middleware requested but corresponding file/middleware is unavailable from router",
            2,
            {
              middleware: allMiddleware,
              requestedMiddleware,
              missingMiddleware: name,
            }
          );
          continue;
        }

        finalMiddleware.push({
          handler: allMiddleware[name](router).handler,
          context: context,
        });
      }
    }

    function populateMiddlewareByArray(middlewareArray) {
      if (!Array.isArray(middlewareArray)) {
        return;
      }

      middlewareArray.forEach((name) => {
        if (!allMiddleware.hasOwnProperty(name)) {
          utilities.debug(
            "warning: middleware requested but corresponding file/middleware is unavailable from router",
            2,
            {
              middleware: allMiddleware,
              requestedMiddleware,
              missingMiddleware: name,
            }
          );
          return true;
        }

        finalMiddleware.push({
          handler: allMiddleware[name](router).handler,
          context: context,
        });
      });
    }

    function populateMiddlewareByString(name) {
      if (typeof name !== "string") {
        utilities.debug(
          "warning: middleware requested but corresponding file/middleware is unavailable from router",
          2,
          {
            middleware: allMiddleware,
            requestedMiddleware,
            missingMiddleware: name,
          }
        );
        return;
      }

      if (!allMiddleware.hasOwnProperty(name)) {
        utilities.debug(
          "warning: middleware requested but corresponding file/middleware is unavailable from router",
          2,
          {
            middleware: allMiddleware,
            requestedMiddleware,
            missingMiddleware: name,
          }
        );
        return true;
      }

      finalMiddleware.push({
        handler: allMiddleware[name](router).handler,
        context: context,
      });
    }

    // case of string
    if (typeof requestedMiddleware === "string") {
      populateMiddlewareByString(requestedMiddleware);
    }

    // case of array
    if (Array.isArray(requestedMiddleware)) {
      populateMiddlewareByArray(requestedMiddleware);
    }
    // case of object
    if (
      requestedMiddleware !== null &&
      typeof requestedMiddleware === "object" &&
      !Array.isArray(requestedMiddleware)
    ) {
      populateMiddlewareByObject(requestedMiddleware);
    }

    let busResult = await runBus(to, from, finalMiddleware, 0, router);
    return busResult;
  };
};
