<template>
  <div :id="uid" class="timeline">
    <slot></slot>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  provide() {
    return {
      timelineActive: computed(() => this.active),
      timelineLineStyle: computed(() => this.lineStyle),
    };
  },
  props: {
    active: {
      type: Number,
      default: 9999999999,
    },
    lineStyle: {
      type: String,
      default: "solid",
    },
  },
  data: function () {
    return {
      uid: "timeline_" + utilities.getUniqueNumber() + "_" + new Date().getTime(),
    };
  },
};
</script>

<style scoped lang="scss"></style>
<style lang="scss"></style>
