<template>
<div class="spacer " style="height: 60px;">

</div>
  <notification-widget> </notification-widget>

  <hr class="margin-max-vertical" />

  <div class="text-center">
    <h1>{{ translateTitleCase("notifications.page.viewTitle") }}</h1>
    <div class="flex flex-center margin-auto max-width-400">
      <div class="timeline-container padding" style="">
        <timeline class="text-inline-start">
          <notification-item
            v-for="(notification, index) in notifications"
            layout="timeline"
            :notification="notification"
            :index="index"></notification-item>
        </timeline>
      </div>
    </div>
  </div>

  <hr class="margin-max-vertical" />


  <notification-item
    v-for="(notification, index) in notifications"
    layout="intro"
    class="max-width-300 margin-m-bottom padding-m-bottom"
    style="border-bottom: 1px solid var(--c-gray)"
    :notification="notification"
    :index="index"></notification-item>
  <hr />

  <hr />
  <div class="div max-width-400">
    <notification-item
      v-for="(notification, index) in notifications"
      layout="default"
      class="margin-xl-bottom padding-l-vertical"
      style="border-bottom: 1px solid var(--c-gray-2)"
      :notification="notification"
      :index="index"></notification-item>
  </div>

  <hr />

  <hr />
  <form-button @click="playground">test</form-button>
  <div class="container border-thing padding">
    <h3 v-if="!notifications">No notifications</h3>
    <badge
      v-if="hasUnreadNotifications"
      class="border-radius-round inline-block"
      style="width: 20px; border-radius: 50%"
      theme="danger"
      :text="unreadNotificationCount"
      :auto-translate="false"></badge>
    <div v-if="notifications" class="notifications">
      <strong>Unread: {{ unreadNotificationCount }}</strong
      ><br />
      <div v-for="notification in notifications">
        <span>Id: {{ notification.id }}</span
        ><br />
        <span>Type: {{ notification.type }}</span
        ><br />
        <span>Data: {{ notification.data }}</span
        ><br />
        <span>CreatedAt: {{ translateDate(notification.createdAt) }}</span
        ><br />
        <span>Reminder: {{ translateDate(notification.lastReminderDateTime) }}</span
        ><br />
        <span>IsRead: {{ notification.isRead ? "yes" : "no" }}</span
        ><br />
        <form-button @click="markNotificationAsRead(notification)"
          >Mark as read</form-button
        >

        <hr class="margin-l-vertical" />
      </div>
    </div>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import Timeline from "@/client/components/base/frameworks/we/timeline/Timeline.vue";
import TimelineItem from "@/client/components/base/frameworks/we/timeline/TimelineItem.vue";
import UserAvatar from "@/client/components/chat/UserAvatar.vue";
import NotificationCenter from "@/client/extensions/mixins/notificationCenter.js";
import NotificationWidget from "@/client/components/etc/NotificationWidget.vue";

export default {
  components: {
    Timeline,
    TimelineItem,
    UserAvatar,
    NotificationWidget,
  },
  mixins: [NotificationCenter],
  layout: "default",
  middleware: {
    requireUser: {},
  },
  props: {
    limit: {
      type: Number,
      default: 100,
    },
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus, socket } = asyncOperations();

    return { asyncOps, asyncOpsReady, asyncStatus, socket };
  },
  data: function () {
    return {};
  },
  meta: "notifications.page.pageTitle",
};
</script>

<style scoped lang="scss"></style>
