<template>
  <layout-user-dashboard>
    <template #default>
     <h1>Welcome back, {{$store.getters['user/profile'].firstName}}</h1>
      <div class="radius-m border-gray bg-bg-2 flex flex-middle flex-center" style="min-height: 50vh;">
        todo: dashboard
      </div>
    </template>
  </layout-user-dashboard>
</template>

<script>
export default {
  layout: {
    component: "default",
    params: {
      title: "userDashboard.main.pageTitle",
      subTitle: "userDashboard.main.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove"
    },
  },
  middleware: {
    requireUser: {},
  },
  meta: "userDashboard.main.browserTitle",
  data: function () {
    return {};
  },

};
</script>

<style scoped lang="scss"></style>
