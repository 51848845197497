<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("ecommerce.orders.displayTitle") }}</span>
    </template>

    <template #default>
      <custom-crud-list v-bind="listConfiguration" @crud-list:items-updated="playground">
        <template #cell-createdAt="{ item }">
          <span>{{ translateDate(item.createdAt, "dateWithTime") }}</span>
        </template>
        <template #cell-totalWithTax="{ item }">
          <span>{{
            translateNumber(item.Quote.grandTotal, "currency", {
              currency: item.Quote.currency,
            })
          }}</span>
        </template>
        <template #cell-numProducts="{ item }">
          <span>{{ translateNumber(item.Quote.totalProductCount) }}</span>
        </template>
        <template #cell-vendors="{ item }">
          <span v-if="item.Vendors && item.Vendors.length > 0">
            {{ item.Vendors.map((vendor) => vendor.name).join(", ") }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell-buyer="{ item }">
          <span v-if="item?.Buyer?.email">{{ item.Buyer.email }}</span>
          <span v-else>-</span>
        </template>
        <template #cell-payments="{ item }">
          <span v-if="Array.isArray(item.Payments) && item.Payments.length > 0">
            {{
              translate(
                "ecommerce.order.fields.orderPaymentSummaryText",
                getPaymentSummaryForOrder(item)
              )
            }}
          </span>
          <span v-else>
            {{ translate("ecommerce.order.paymentStatus.noPayments") }}
          </span>
        </template>
      </custom-crud-list>

      <modal ref="orderViewModal" size="l" title="Order Details">
        <spinner v-if="!order"></spinner>

        <block class="margin-2xl-bottom">
          <span
            ><strong>{{ translateUcFirst("ecommerce.order.fields.id") }}:</strong>
            {{ order.id }}</span
          ><br />
          <span
            ><strong>{{ translateUcFirst("entity.user.fields.fullName") }}:</strong>
            {{ order?.Buyer?.fullName }}</span
          ><br />
          <span
            ><strong>{{ translateUcFirst("entity.user.fields.email") }}:</strong>
            {{ order?.Buyer?.email }}</span
          ><br />
          <span>
            <strong>{{ translateUcFirst("ecommerce.order.fields.payment") }}: </strong>
            <span v-if="Array.isArray(order.Payments) && order.Payments.length > 0">
              {{
                translate(
                  "ecommerce.order.fields.orderPaymentSummaryText",
                  getPaymentSummaryForOrder(order)
                )
              }}
            </span>
            <span v-else>
              {{ translate("ecommerce.order.paymentStatus.noPayments") }}
            </span> </span
          ><br />
        </block>

        <card title="ecommerce.order.admin.quoteWithExplain" class="margin-2xl-bottom">
          <cart :quote="order.Quote" :read-only="true" :show-action-bar="false"> </cart>
        </card>
        <card title="ecommerce.order.admin.buyerTitle" class="margin-2xl-bottom">
          <simple-value-list :list="buyerInformationList"></simple-value-list>
        </card>
      </modal>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";
import Cart from "@/client/components/ecommerce/Cart.vue";
export default {
  components: { CustomCrudList, Cart },
  layout: "adminDashboard",
  middleware: {
    requireSuperAdmin: {},
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.orders.pageTitle",
  data: function () {
    return {
      order: false,
      userForm: false,
      listConfiguration: {
        subject: "order",
        listSrc: "ecommerce/order",
        initialOrdering: { key: "createdAt", direction: "desc" },
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: false, //bool, default false
        enableMassActions: false,
        editRoutePrefix: "admin-",
        createRoutePrefix: "admin-",
        deleteRoutePrefix: "ecommerce/",
        additionalListData: {
          simplified: false,
          translate: false,
        },
        fields: {
          // these are the table columns
          status: {
            itemKey: "status",
            label: "ecommerce.order.fields.status",
            filterable: {
              name: "status",
              type: "select2",
              placeholder: "ecommerce.order.fields.status",
              translateList: true,
              list: [
                { value: "new", label: "ecommerce.order.fields.statuses.new" },
                { value: "canceled", label: "ecommerce.order.fields.statuses.canceled" },
                { value: "payed", label: "ecommerce.order.fields.statuses.payed" },
                { value: "approved", label: "ecommerce.order.fields.statuses.approved" },
                {
                  value: "completed",
                  label: "ecommerce.order.fields.statuses.completed",
                },
              ],
            }, // boolean, or object
            quickEdit: {
              field: {
                type: "select",
                translateList: true,
                list: config.ecommerce.order.statuses.map((type) => ({
                  value: type,
                  label: "ecommerce.order.fields.statuses." + type,
                })),
              },
            },
            orderable: true,
          },
          createdAt: {
            itemKey: "createdAt",
            label: "ecommerce.order.fields.createdAt",
          },
          totalWithTax: {
            label: "ecommerce.order.fields.totalWithTax",
            orderable: false,
          },
          numProducts: {
            label: "ecommerce.order.fields.numProducts",
            orderable: false,
          },
          vendors: {
            label: "ecommerce.order.fields.vendors",
            orderable: false,
          },
          buyer: {
            label: "ecommerce.order.fields.buyer",
            orderable: false,
          },
          payments: {
            label: "ecommerce.order.fields.payment",
            orderable: false,
            filterable: false,
          },
          createdFrom: {
            listable: false,
            filterable: {
              name: "createdFrom",
              type: "DatePicker",
              placeholder: "ecommerce.order.fields.createdFrom",
            },
            type: "DatePicker",
            label: "ecommerce.order.fields.createdFrom",
          },
          createdBefore: {
            listable: false,
            filterable: {
              name: "createdBefore",
              type: "DatePicker",
              placeholder: "ecommerce.order.fields.createdBefore",
            },
            label: "ecommerce.order.fields.createdBefore",
          },
          productCodes: {
            label: "ecommerce.order.fields.productCodes",
            listable: false,
            filterable: {
              name: "productCodes",
              placeholder: "ecommerce.order.fields.productCodes",
            },
          },

          /*
          cartContent: {
            label: "ecommerce.order.fields.vendorId",
            listable: false,
            filterable: {
              name: "vendorId",
              placeholder: "ecommerce.order.fields.vendorId",
            },
          }
           */
          // vendor filter
          // product name search
        },
        actions: {
          show: false,
          edit: false,
          delete: false,
          custom: {
            quickShow: {
              //tooltip
              icon: "eye",
              theme: "inverse",
              //text
              handler: ({ item }) => {
                if (item) {
                  this.showOrder(item);
                }
              },
            },
          },
        },
      },
      asyncData: {
        userForm: "entity/user/update",
      },
    };
  },
  computed: {
    buyerInformationList() {
      const res = [];
      //  console.log(this.userForm.fields);
      Object.entries(this.userForm.fields).forEach(([key, field]) => {
        let label;

        if (field.placeholder) {
          label = field.placeholder;
        }
        if (field.label) {
          label = field.label;
        }
        res.push({ label: label, value: this?.order?.Buyer[key] });
      });
      return res;
    },
  },
  mounted() {
    console.log(config.ecommerce.order.statuses);
  },
  methods: {
    getPaymentSummaryForOrder(order) {
      let res = {
        total: 0,
        count: 0,
        status: "-",
      };
      if (!order.Payments || order.Payments.length < 1) {
        return res;
      }
      res.status = this.t("ecommerce.order.paymentStatus.complete");
      order.Payments.forEach((payment) => {
        res.total = res.total + payment.amount;

        if (payment.status !== "completed") {
          res.status = this.t("ecommerce.order.paymentStatus.incomplete");
        }
      });

      res.total = this.translateNumber(res.total, "currency", {
        currency: order.Quote.currency,
      });
      return res;
    },
    showOrder(order) {
      console.log("order", order);
      this.order = order;
      this.$refs["orderViewModal"].open();
    },
    playground(data) {
      if (data) {
        // this.showOrder(data[10]);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
