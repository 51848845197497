<template>
  <div class="relative">
    <div
      class="trigger-container flex relative cursor-pointer"
      @click="isDropDownOpened ? null : (isDropDownOpened = true)">
      <badge
        v-if="hasUnreadNotifications"
        class="absolute z-level-2"
        theme="danger"
        :border="2"
        :round="true"
        :border-theme="'bg'"
        size="s"
        :text="unreadNotificationCount"
        style="
          position: absolute;
          top: -0.8em;
          inset-inline-end: -0.8em;
          min-width: 2em;
        "></badge>
      <icon icon="bell" :size="1.5"></icon>
    </div>
    <div
      ref="listDropDown"
      v-bind="dropdownProps"
      v-click-outside="{ handler: closeDropdownImmediate }"
      v-dropdown="{ opened: isDropDownOpened, applyAnimations: true }"
      class="notification-widget-dropdown relative"
      @dropdown:closed="isDropDownOpened = false"
      @click.stop="">
      <div class="notification-widget-dropdown__header">
        <icon
          class="notification-widget-dropdown__close-icon"
          icon="x"
          @click="isDropDownOpened = false"></icon>
        <h6 class="margin-remove text-semibold text-m">
          {{ translateTitleCase("notifications.general.yourNotifications") }}
        </h6>
      </div>
      <div class="notification-widget-dropdown__content">
        <div v-for="(notification, index) in notifications" class="notification-wrapper">
          <notification-item
            layout="intro"
            class="padding-l"
            :notification="notification"
            :index="index"></notification-item>
          <div
            v-if="index + 1 < notifications?.length"
            class="notification-spacer"
            style="height: 1px; background: var(--c-gray)"></div>
        </div>
      </div>
      <div class="notification-widget-dropdown__footer">
        <slot name="footer-actions">
          <icon-text v-if="hasUnreadNotifications" icon="check" class="c-success text-s">
            <a
              class="c-success text-decoration-none cursor-pointer"
              @click.prevent="markAllAsRead">
              {{ translateUcFirst("notifications.general.markAllAsRead") }}
            </a>
          </icon-text>
          <slot name="notification-center-cta">
            <saffron-link
              v-slot="{ navigate }"
              style="margin-inline-start: auto"
              class="plain-link"
              :to="{ name: 'notifications' }">
              <form-button
                theme="inverse"
                size="s"
                icon-end="chevron-inline-end"
                @click="goToNotificationCenter(navigate)">
                {{ translateUcFirst("notifications.widget.goToNotificationsCta") }}
              </form-button>
            </saffron-link>
          </slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationCenter from "@/client/extensions/mixins/notificationCenter.js";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";

export default {
  mixins: [NotificationCenter],
  props: {
    limit: {
      type: Number,
      default: 10,
    },
    dropdownProps: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus, socket } = asyncOperations();

    return { asyncOps, asyncOpsReady, asyncStatus, socket };
  },
  data: function () {
    return {
      isDropDownOpened: false,
    };
  },
  methods: {
    async markAllAsRead() {
      let res = await this.asyncOps.asyncCall(
        "notification/mark-all-as-read",
        {},
        { method: "patch" }
      );

      if (res.hasError) {
        this.$s.ui.notification("core.genericError", "error");
        return false;
      }

      await this.refreshAsyncData();
      this.isDropDownOpened = false;

      //  let res = await this.asyncOps.asyncCall('notification/type/test2/mark-all-as-read', {}, {method: 'patch'});

      //    if ( res.hasError) {
      //     this.$s.ui.notification('core.genericError','error');
      //    return false;
      //   }

      //    await this.refreshAsyncData();
      //   this.isDropDownOpened = false;
    },
    goToNotificationCenter(navigateFunction) {
      this.isDropDownOpened = false;
      if (typeof navigateFunction === "function") {
        navigateFunction();
      }
    },
    closeDropdownImmediate() {
      this.isDropDownOpened = false;
    },
    async markAllAsReadOnServer() {},
  },
};
</script>

<style scoped lang="scss">
.notification-widget-dropdown {
  border: 1px solid var(--c-gray);
  background: var(--c-bg);
  overflow: hidden;

  &__header {
    position: relative;
    border-bottom: 1px solid var(--c-gray);
    box-shadow: var(--box-shadow-1);
    padding-inline: var(--margin-m);
    padding-block: var(--margin-m);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--margin-xl);
    min-width: 300px;
    text-align: center;
  }

  &__close-icon {
    position: absolute;
    z-index: var(--z-level-2);
    inset-inline-start: var(--margin-m);
    top: 50%;
    cursor: pointer;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: transform 250ms ease;

    &:hover {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__content {
    max-height: 300px;
    overflow-y: auto;
  }

  &__footer {
    border-top: 1px solid var(--c-gray);
    box-shadow: var(--box-shadow-1);
    padding-inline: var(--margin-m);
    padding-block: var(--margin-m);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--margin-xl);
    min-width: 300px;
    text-align: center;
  }
}
</style>
