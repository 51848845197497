import Home from "@/client/views/Home.vue";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      layout: "default",
    },
  },
];
