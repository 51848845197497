<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ tt("userDashboard.vendor.products.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ tu("userDashboard.vendor.products.introText") }}
      </p>

      <custom-crud-list v-bind="listConfiguration">
        <template #cell-introImage="{ item }">
          <img
            :src="item.introImage"
            style="max-height: 50px; width: auto; max-width: 200px"
            alt="" />
        </template>
        <template #cell-state="{ item }">
          <span v-if="item.state" class="c-success">
            <icon icon="check"></icon>
          </span>
          <span v-else class="c-error">
            <icon icon="x"></icon>
          </span>
        </template>
        <template #cell-featured="{ item }">
          <span v-if="item.featured" class="c-success">
            <icon icon="check"></icon>
          </span>
          <span v-else="item.featured" class="c-error">
            <icon icon="x"></icon>
          </span>
        </template>
      </custom-crud-list>
    </template>
  </layout-user-dashboard>
</template>

<script>
import { computed } from "vue";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";

export default {
  components: {
    CustomCrudList,
  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.vendor.products.pageTitle",
      subTitle: "userDashboard.vendor.products.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
    },
  },
  middleware: {
    requireUser: {},
    requireVendor: {},
  },
  data: function () {
    return {
      listConfiguration: {
        subject: "product",
        listSrc: "ecommerce/product",
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: false, //bool, default false
        enableMassActions: false,
        additionalListData: {
          simplified: false,
          translate: false,
        },
        constantFilters: {
          VendorId: computed(() => this.$store.getters["user/profile"]?.Vendor?.id),
        },
        fields: {
          name: {
            itemKey: "name",
            label: "ecommerce.product.fields.name",
            filterable: true, // boolean, or object
            orderable: true,
          },
          introImage: {
            itemKey: "introImage",
            label: "ecommerce.product.fields.introImage",
          },
          state: {
            itemKey: "state",
            label: "core.entity.state",
            filterable: true, // boolean, or object
            orderable: true,
          },
          featured: {
            itemKey: "featured",
            label: "ecommerce.product.fields.featured",
            filterable: true, // boolean, or object
            orderable: true,
          },
        },
        actions: false,
      },
    };
  },
  methods: {
    getPaymentSummaryForOrder(order) {
      let res = {
        total: 0,
        count: 0,
        status: "-",
      };
      if (!order.Payments || order.Payments.length < 1) {
        return res;
      }
      res.status = this.t("userDashboard.vendor.products.paymentStatus.complete");
      order.Payments.forEach((payment) => {
        res.total = res.total + payment.amount;

        if (payment.status !== "completed") {
          res.status = this.t("userDashboard.vendor.products.paymentStatus.incomplete");
        }
      });

      res.total = this.translateNumber(res.total, "currency", {
        currency: order.Quote.currency,
      });
      return res;
    },
  },
  meta: "userDashboard.vendor.products.browserTitle",
};
</script>

<style scoped lang="scss"></style>
