<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-vendor' }">
    <template #title>
      <span>{{
        translateTitleCase("ecommerce.vendor.create.displayTitle")
      }}</span>
    </template>

    <template #default>
      <custom-crud-create v-bind="createConfiguration"></custom-crud-create>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudCreate from "@/client/components/crud/customV1/Create.vue";
import { computed } from "vue";
export default {
  components: { CustomCrudCreate },
  layout: "adminDashboard",

  middleware: {
    requireSuperAdmin: {},
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.vendor.create.pageTitle",
  data: function () {
    let temp = {
      formConfig: {
        fieldWrapperClass: "",
        /*
         groups: {
         group1: {
         component: 'FormFieldSet',
         props: {
         title: 'shineAdmin.eventManager.eventDetailsFieldsetTitle',
         icon: 'calendar',
         maxFieldsPerRow: 4
         }
         },
         group2: {
         component: 'FormFieldSet',
         props: {
         title: 'shineAdmin.eventManager.eventMediaFieldsetTimeframe',
         icon: 'clock',
         maxFieldsPerRow: 3
         }
         },
         group3: {
         component: 'FormFieldSet',
         props: {
         title: 'shineAdmin.eventManager.eventMediaFieldsetTitle',
         icon: 'user',
         maxFieldsPerRow: 3
         }
         },
         },
         */
        fields: {
          // these are the table columns
          name: {
            name: "name",
            type: "text",
            label: "ecommerce.vendor.fields.name",
            icon: "pencil",
            validation: {
              required: {},
              /*
               minimum: {
               params: { min: 2 },
               },
               */
            },
          },
        },
        formHandling: {
          /*
           preflight: [
           {
           type   : 'validation',
           options: {}
           },
           {
           type   : 'submitDataMutation',
           options: {
           handler: async ({data, next, quit}) => {
           if (data.clientPhoneNumber && typeof data.clientPhoneNumber === 'object') {
           data.clientPhoneNumber = String(data.clientPhoneNumber.prefix) + String(data.clientPhoneNumber.number);
           }

           return next();
           }
           }
           },
           ]
           */
        },
      },
    };
    return {
      createConfiguration: {
        subject: "vendor",
        submitUrl: "ecommerce/vendor/",
        cancelRedirect: { name: "admin-vendor" },
        successRedirect: { name: "admin-vendor" },
        initialItemData: {},
        formSource: "entity/vendor/create",
      },
      asyncData: {},
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
