<template>
  <div>
    <h1>{{ t("ecommerce.vendors.displayTitle") }}</h1>
  </div>
</template>

<script>
export default {
  layout: "default",
  data: function () {
    return {};
  },
  meta: "ecommerce.vendors.pageTitle",
};
</script>

<style scoped lang="scss">

</style>
