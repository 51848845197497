import asyncFactory from "@/client/extensions/modules/asyncComponentFactory.js";

export default [
  /* user pages are off by default. implement in applet router
   {
   path: '/user/register',
   name: 'userRegister',
   component: asyncFactory('views/Register.vue'),
   meta : {
   'layout' : 'boxy',
   'layoutParams': {
   'backgroundImage': 'core/images/user/login/boxy_background.jpg'
   },
   requiresGuest : true,

   }

   },
   {
   path: '/user/profile',
   name: 'userProfile',
   component: asyncFactory('views/UserProfile.vue'),
   meta : {
   //    'layout' : 'userDashboard',
   requiresUser : true
   }
   }
   */
];
