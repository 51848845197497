<template>
  <block class="crud-create">
    <div class="action-bar flex-between flex-wrap padding-m-vertical margin-xl-bottom">
      <div class="top-actions flex flex-middle gap-l animate__fadeIn animate--fast">
        <form-button
          v-if="showCancelButton"
          theme="gray-5"
          icon="chevron-inline-start"
          @click="doCancel"
          >{{ translate("core.crudV1.cancel") }}</form-button
        >
        <form-button
          v-if="showTopSubmitButton"
          theme="success"
          icon="check"
          @click="doSubmit"
          >{{ translate("core.crudV1.save") }}</form-button
        >
      </div>
    </div>
    <!--
    <pre>
      {{itemData}}
    </pre>-->
    <skeleton
      :default-height="500"
      template="thinLines"
      :count="8"
      :content-ready="formLoaded">
      <div class="form-container">
        <base-form
          v-bind="finalFormConfig"
          ref="form"
          v-model="itemData"
          @form:submitSuccess="handleSuccess"
          @update:modelValue="emitItemUpdated"></base-form>
        <form-button theme="success" full-width="true" icon="check" @click="doSubmit">{{
          translate("core.crudV1.save")
        }}</form-button>
      </div>
    </skeleton>
  </block>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import _ from "lodash/object";

export default {
  components: {},
  props: {
    subject: {
      type: String,
      default: "generic",
    },
    submitUrl: {
      type: [String, Boolean],
      default: false,
    },
    submitMethod: {
      type: String,
      default: "post",
    },
    cancelRedirect: {
      type: [String, Boolean, Function, Object],
      default: false,
    },
    successRedirect: {
      type: [String, Boolean, Function, Object],
      default: false,
    },
    formConfig: {
      type: [String, Object, Boolean],
      default: false,
    },
    initialItemData: {
      type: Object,
      default: {},
    },
    formSource: {
      type: [String, Boolean],
      default: false,
    },
    formSourceData: {
      type: Object,
      default: {},
    },
    formSourceMutator: {
      type: Function,
      default: (data) => data,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showTopSubmitButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["form:dateUpdated", "item:updated"],
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      defaultRoutePrefix: "",
      formLoaded: false,
      remoteFormConfig: {},
      itemData: {
        ...this.initialItemData,
        //    fullName: 'Test user ' + Math.round(Math.random(0, 100) * 1000),
        //    email: 'testuser' + Math.round(Math.random(0, 100) * 1000) + '@gmail.com',
        //     phoneNumber: '+972544894' + Math.round(Math.random(0, 100) * 1000),
        //    password: '12345678'
      },
    };
  },
  computed: {
    finalFormConfig() {
      let conf;

      if (!this.formLoaded) {
        return {};
      }

      conf = { ...this.remoteFormConfig };

      if (this.formConfig && typeof this.formConfig === "object") {
        conf = _.merge(conf, this.formConfig);
      }

      conf.method = this.submitMethod;
      conf.action = this.submitUrl
        ? this.submitUrl
        : this.defaultRoutePrefix + this.subject;
      conf.buttons = false;
      return conf;
    },
  },
  watch: {
    formSource: {
      handler(newVal) {
        if (newVal) {
          this.formLoaded = false;
          this.loadFromBySource(newVal);
        } else {
          // no source - using parent rules
          this.formLoaded = true;
        }
      },
      immediate: true,
    },
    itemData: {
      handler(newVal) {
        this.$emit("item:updated", { itemData: newVal });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async loadFromBySource(source) {
      try {
        let formRequestResult = await this.asyncOps.asyncCall(
          this.formSource,
          this.formSourceData,
          { method: "get" }
        );
        if (formRequestResult.isError) {
          this.$s.ui.notification("core.errorGeneric", "error");
        }
        this.remoteFormConfig = this.formSourceMutator(formRequestResult.data);
        this.formLoaded = true;
      } catch (e) {
        this.$s.ui.notification("core.errorGeneric", "error");
      }
    },
    doNavigation(arg) {
      if (typeof arg === "function") {
        return this.cancel();
      }

      if (arg && typeof arg === "object") {
        this.$router.push(arg);
      }

      if (typeof arg === "string") {
        this.$router.push({ name: arg });
      }

      // default to back
      this.$router.back();
    },
    handleSuccess() {
      this.$s.ui.notification("core.crudV1.createSuccessNotification", "success");
      this.doNavigation(this.successRedirect);
    },
    doCancel() {
      this.doNavigation(this.cancelRedirect);
    },
    submit() {
      this.$refs.form.submit();
    },
    doSubmit() {
      return this.submit();
    },
    emitItemUpdated(formData) {
      this.$emit("form:dataUpdated", formData);
    },
    setItemValue(key, value) {
      this.itemData[key] = value;
    },
    setItemData(data) {
      if (!data || typeof data !== "object") {
        warn("Crud create: can not set item data, argument is not an object", data);
        return false;
      }
      this.itemData = { ...data };

      return true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
