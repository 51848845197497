<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-product' }">
    <template #title>
      <span>{{ translateTitleCase("admin.products.create.displayTitle") }}</span>
    </template>

    <template #default>
      <div class="block relative">
        <spinner v-if="!isFormReady" overlay="absolute"></spinner>
        <custom-crud-create
          v-bind="createConfiguration"
          @item:updated="handleItemUpdated"></custom-crud-create>
      </div>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import useProducts from "@/client/extensions/composition/useProducts";
import CustomCrudCreate from "@/client/components/crud/customV1/Create.vue";

// TODO: something not working with tags
let testProductData = {
  images: [
    {
      url: "https://fileserver.saffron-technologies.com/api/files/1beb3bef-80b4-4b3c-9263-88c263dc621b/e9c5a114-9dd0-43eb-9d01-38104375fd8b.jpeg",
    },
  ],
  additionalInformation: [
    {
      title: "tab1",
      content: {
        time: 1673961845316,
        blocks: [
          {
            id: "-ZhN0pbjiB",
            type: "paragraph",
            data: {
              text: "test1",
            },
          },
        ],
        version: "2.26.4",
      },
    },
  ],
  typeList: ["default"],
  CategoryId: 3,
  Tags: [],
  OwnerId: 11,
  VendorId: 1,
  price: 5550,
  DefaultProductType: {
    OwnerId: 11,
    param1: "test 123",
    translations: {
      "en-US": {
        param1: "bogle 332",
      },
    },
  },
  name: "Test 1",
  information: {
    time: 1673961827164,
    blocks: [
      {
        id: "dcb2YYsB-i",
        type: "paragraph",
        data: {
          text: "test info<br>",
        },
      },
    ],
    version: "2.26.4",
  },
  introImage:
    "https://fileserver.saffron-technologies.com/api/files/1beb3bef-80b4-4b3c-9263-88c263dc621b/228e63ce-4639-4543-bb44-42e18a9ef3ae.jpeg",
};

import { computed } from "vue";
export default {
  components: { CustomCrudCreate },
  layout: "adminDashboard",
  middleware: {
    requireSuperAdmin: {},
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    let { productForm } = useProducts(props);

    return {
      asyncOps,
      asyncOpsReady,
      asyncStatus,
      productForm,
    };
  },
  meta: "admin.products.create.pageTitle",
  data: function () {
    let temp = {
      formConfig: {
        fieldWrapperClass: "",
        /*
         groups: {
         group1: {
         component: 'FormFieldSet',
         props: {
         title: 'shineAdmin.eventManager.eventDetailsFieldsetTitle',
         icon: 'calendar',
         maxFieldsPerRow: 4
         }
         },
         group2: {
         component: 'FormFieldSet',
         props: {
         title: 'shineAdmin.eventManager.eventMediaFieldsetTimeframe',
         icon: 'clock',
         maxFieldsPerRow: 3
         }
         },
         group3: {
         component: 'FormFieldSet',
         props: {
         title: 'shineAdmin.eventManager.eventMediaFieldsetTitle',
         icon: 'user',
         maxFieldsPerRow: 3
         }
         },
         },
         */

        fields: {
          // these are the table columns
          name: {
            name: "name",
            type: "text",
            label: "ecommerce.product.fields.name",
            icon: "pencil",
            validation: {
              required: {},
              /*
               minimum: {
               params: { min: 2 },
               },
               */
            },
          },
        },
        formHandling: {
          /*
           preflight: [
           {
           type   : 'validation',
           options: {}
           },
           {
           type   : 'submitDataMutation',
           options: {
           handler: async ({data, next, quit}) => {
           if (data.clientPhoneNumber && typeof data.clientPhoneNumber === 'object') {
           data.clientPhoneNumber = String(data.clientPhoneNumber.prefix) + String(data.clientPhoneNumber.number);
           }

           return next();
           }
           }
           },
           ]
           */
        },
      },
    };
    return {
      isFormReady: computed(() => this.productForm.isReady),
      createConfiguration: {
        subject: "product",
        submitUrl: "ecommerce/product/",
        cancelRedirect: { name: "admin-product" },
        successRedirect: { name: "admin-product" },
        initialItemData: {
          typeList: config.ecommerce.productTypes.defaultProductTypes,
        }, //testProductData,
        formConfig: computed(() => this.productForm.config),
      },
      asyncData: {},
    };
  },
  computed: {},
  created() {
    this.productForm.adjustTypes(config.ecommerce.productTypes.defaultProductTypes, true);
  },
  methods: {
    handleItemUpdated({ itemData }) {
      let targetTypes = Array.isArray(itemData.typeList) ? itemData.typeList : config.ecommerce.productTypes.defaultProductTypes;
      this.productForm.adjustTypes(targetTypes);
    },
  },
};
</script>

<style scoped lang="scss"></style>
