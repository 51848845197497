<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">
        {{ translateTitleCase("userDashboard.notifications.pageTitle") }}
      </h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.notifications.introText") }}
      </p>

      <div v-if="notifications" class="flex flex-center margin-auto max-width-400">
        <div class="timeline-container padding" style="">
          <timeline class="text-inline-start">
            <notification-item
              v-for="(notification, index) in notifications"
              layout="timeline"
              :notification="notification"
              :index="index"></notification-item>
          </timeline>
        </div>
      </div>

      <h3 v-if="!notifications" class="text-center">
        {{ tu("userDashboard.notifications.noNotifications") }}
      </h3>
    </template>
  </layout-user-dashboard>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import Timeline from "@/client/components/base/frameworks/we/timeline/Timeline.vue";
import TimelineItem from "@/client/components/base/frameworks/we/timeline/TimelineItem.vue";
import UserAvatar from "@/client/components/chat/UserAvatar.vue";
import NotificationCenter from "@/client/extensions/mixins/notificationCenter.js";

export default {
  components: {
    Timeline,
    TimelineItem,
    UserAvatar,
  },
  mixins: [NotificationCenter],
  layout: {
    component: "default",
    params: {
      title: "userDashboard.notifications.pageTitle",
      subTitle: "userDashboard.notifications.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
      viewTitle: "userDashboard.notifications.pageTitle",
      viewExplain: "userDashboard.notifications.introText",
    },
  },
  middleware: {
    requireUser: {},
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus, socket } = asyncOperations();

    return { asyncOps, asyncOpsReady, asyncStatus, socket };
  },
  limit: {
    type: Number,
    default: 100,
  },
  data: function () {
    return {};
  },
  computed: {
    userId() {
      return this.$store.getters["user/profile"].id;
    },
  },
  meta: "userDashboard.notifications.browserTitle",
};
</script>

<style scoped lang="scss"></style>
