import {
  getCurrentInstance,
  reactive,
  ref,
  computed,
  watchEffect,
  watch,
} from "vue";
import asyncOperations
  from "@/client/extensions/composition/asyncOperations.js";

const adapters = {
  saffron: class SaffronMediaManagerAdaptor {
    asyncOps = null;

    constructor (options = {}) {
      this.asyncOps = asyncOperations({}).asyncOps;
      return this;
    }

    getAdaptorType () {
      console.log("test media manager - saffron adaptor");
      return "saffron";
    }

    async _getUploadToken (file) {
      // get token
      const path        = config.mediaManager.saffron.getTokenRelativeUrl;
      const payload     = { action: "create" };
      const tokenResult = await this.asyncOps.asyncCall(path, payload, {
        method: "post",
      });

      if (tokenResult.isError) {
        return false;
      }

      let { token, accountUUID } = tokenResult.data;
      return { token, accountUUID };
    }

    async _uploadFileToFileServer (accountUUID, token, file) {
      const uploadPath    = config.mediaManager.saffron.uploadFilePath;
      const uploadData    = {
        accountUUID,
        token,
        file,
      };
      const uploadOptions = {
        asFormData    : true,
        method        : "post",
        requestUrlFull: true,
      };
      const uploadResult  = await this.asyncOps.asyncCall(
        uploadPath,
        uploadData,
        uploadOptions,
      );
      if (uploadResult.isError) {
        return false;
      }

      return uploadResult.data.fileName;
    }

    async _createMediaByHandleAndFile (handle, file, data = {}) {
      let createMediaUrl     = config.mediaManager.saffron.baseRelativeUrl;
      let createMediaPayload = {
        fileName: file.name.replace(/[^a-zA-Z0-9-_/.]/g, "_").
          replace(/_{2,}/g, "_"),
        mimeType: file.type,
        type    : file.type.split("/").shift(),
        handler : "saffron",
        handle  : handle,
        ...data,
      };

      const createResult = await this.asyncOps.asyncCall(
        createMediaUrl,
        createMediaPayload,
        { method: "post" },
      );

      if (createResult.isError) {
        return false;
      }

      return createResult.data;
    }

    async upload (file, options = {data: {}}) {

      // get token
      let data = options.data ? options.data : {};
      const tokenResult = await this._getUploadToken(file);

      if ( ! tokenResult) {
        return false;
      }

      let { token, accountUUID } = tokenResult;

      const handle = await this._uploadFileToFileServer(
        accountUUID,
        token,
        file,
      );

      if ( ! handle) {
        return false;
      }

      const createResult = await this._createMediaByHandleAndFile(handle, file,      data);

      if ( ! createResult) {
        return false;
      }

      return createResult;
    }

    async rename (id, newName) {
      let updateMediaUrl     =
            config.mediaManager.saffron.baseRelativeUrl + "/" + id;
      let updateMediaPayload = {
        fileName: newName,
      };

      const updateResult = await this.asyncOps.asyncCall(
        updateMediaUrl,
        updateMediaPayload,
        { method: "patch" },
      );

      if (updateResult.isError) {
        return false;
      }

      return updateResult.data;
    }

    async delete (id) {
      let updateMediaUrl     =
            config.mediaManager.saffron.baseRelativeUrl + "/" + id;
      let updateMediaPayload = {};

      const updateResult = await this.asyncOps.asyncCall(
        updateMediaUrl,
        updateMediaPayload,
        { method: "delete" },
      );

      if (updateResult.isError) {
        return false;
      }

      return true;
    }
  },
};

const implementsAdapterInterface = (candidate) => {
  if (typeof candidate !== "object" || ! candidate) {
    return false;
  }
  const functions = ["getAdaptorType", "upload", "rename", "delete"];

  for (const functionName of functions) {
    if (typeof candidate[functionName] !== "function") {
      return false;
    }
  }

  return true;
};

export default (props, options = {}) => {
  let adapterName = options.adapter
    ? options.adapter
    : config.mediaManager.adapter;

  if ( ! adapters[adapterName]) {
    warn("Can not find adapter for media manager, check your config", {
      adapterName,
      props,
      options,
    });
  }

  let adaptor = new adapters[adapterName](options);

  if ( ! implementsAdapterInterface(adaptor)) {
    warn(
      "adaptor does not meet interface requirements, check your code (media manager)",
    );
  }

  ///////////////

  const asyncOps = asyncOperations({}).asyncOps;

  const pageSize = 50;

  const defaultListSettings = {
    filters   : {},
    pagination: {
      start: 0,
      limit: pageSize,
    },
    ordering  : {
      key      : "fileName",
      direction: "asc",
    },
  };

  const listDefinition      = reactive({
    ...defaultListSettings,
  });

  const resetList = () => {
    listDefinition.filters    = defaultListSettings.filters;
    listDefinition.pagination = defaultListSettings.pagination;
    listDefinition.ordering   = defaultListSettings.ordering;
  };

  const items = ref([]);

  const totalItems = ref(0);

  const isLoadingItems = ref(false);

  const hasItems = computed(() => items.value.length > 0);

  const hasError = ref(false);

  const refreshListItems = async () => {
    isLoadingItems.value = true;
    const result         = await asyncOps.asyncCall("entity/media", listDefinition);

    if (result.isError) {
      hasError.value        = true;
      items.value          = [];
      isLoadingItems.value = false;
    }

    items.value          = result.data.items;
    totalItems.value = Number(result.data.totalCount);
    hasError.value        = false;
    isLoadingItems.value = false;

    return result;
  };

  watch(listDefinition,
    (newValue,oldValue) => {
      refreshListItems();
    },
    { immediate: false, deep: true },
  );

  const setFilter = (name, value) => {
    listDefinition.filters[name] = value;
    setLimit(pageSize);
    return true;
  };

  const unsetFilter = (name) => {
    if (listDefinition.filters.hasOwnProperty(name)) {
      delete listDefinition.filters[name];
      setLimit(pageSize);
      return true;
    }
  };

  const setLimit = (limit) => {
    listDefinition.pagination.limit = Number(limit);
  }

  const loadMore = () => {
    listDefinition.pagination.limit = listDefinition.pagination.limit + pageSize;
  }

  // list behaviour
  return {
    items,
    totalItems,
    hasItems,
    isLoadingItems,
    hasError,
    upload: adaptor.upload.bind(adaptor),
    rename: adaptor.rename.bind(adaptor),
    delete: adaptor.delete.bind(adaptor),
    resetList,
    refreshListItems,
    loadItems: refreshListItems,
    reloadItems: refreshListItems,
    reload: refreshListItems,
    setFilter,
    unsetFilter,
    setLimit,
    loadMore,
  };
};
