<template>
  <div class=" ">
    <div
      v-for="(child, key) of tree.children"
      v-if="hasChildren"
      :key="getUniqueKey()"
      class="padding-m-inline-start children margin-m-bottom padding-s bg-gray-1">
      <div class="inline-block">
        <flex
          class="flex gap-s flex-start flex-middle cursor-pointer"
          @click="toggleNode(key)">
          <icon-halo
            v-if="!isNodeOpen(key)"
            halo-theme="lead"
            theme="bg"
            icon="chevron-right"></icon-halo>
          <icon-halo
            v-if="isNodeOpen(key)"
            halo-theme="lead"
            theme="bg"
            icon="chevron-down"></icon-halo>
          <strong>{{ key }}</strong>
        </flex>
      </div>
      <!-- TODO: we need an expanded state for each one of these, or they all open at once -->
      <use-case-acl-tree
        v-if="isNodeOpen(key)"
        :tree="child"
        :path="getChildPath(child.name)"
        class="animate__fadeIn animate__slow">
      </use-case-acl-tree>
    </div>

    <div v-if="!hasChildren" class="padding-m-inline-start">
      <slot name="leaf" v-bind="{ leaf: tree }">
        <div class="margin-medium-top padding-s bg-gray-2">
          <block class="margin-m-bottom">
            <strong>System Path: {{ getStrPath(path) }}</strong
            ><br />
          </block>
          <strong v-if="tree.value && tree.value.length < 1">no rules</strong>
          <block v-else>
            <block class="margin-small-bottom">
              <span>Required permissions:</span>
            </block>
            <div class="flex flex-between gap-m">
              <div class="flex flex-wrap gap-m width-expand">
                <block
                  v-for="rule in tree.value"
                  class="padding-small flex-wrap radius-s bg-gray-1">
                  <span>Subject: </span><strong>{{ rule.subject }}</strong
                  ><br />
                  <span>Actions: </span>
                  <span>
                    <span v-for="(action, actionIndex) of rule.actions">
                      <span>{{ action }}</span>
                      <span v-if="actionIndex < rule.actions.length - 1"
                        >,
                      </span>
                    </span>
                  </span>
                </block>
              </div>
              <div class="acl-check border padding-m">block</div>
            </div>
          </block>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "UseCaseAclTree",
  props: {
    tree: {
      type: Object,
    },
    path: {
      type: Array,
      default: [],
    },
  },
  data: function () {
    return {
      expanded: false,
      openNodes: new Set(),
    };
  },
  computed: {
    hasChildren() {
      return this.tree.children && Object.values(this.tree.children).length > 0;
    },
  },
  watch: {},
  methods: {
    getUniqueKey() {
      return utilities.getUniqueNumber();
    },
    isNodeOpen(id) {
      return this.openNodes.has(id);
    },
    openNode(id) {
      this.openNodes.add(id);
    },
    closeNode(id) {
      this.openNodes.delete(id);
    },
    toggleNode(id) {
      if (this.isNodeOpen(id)) {
        this.closeNode(id);
      } else {
        this.openNode(id);
      }
    },
    getChildPath(childKey) {
      return [...this.path, childKey];
    },
    getStrPath() {
      return this.path.join(".");
    },
  },
};
</script>

<style scoped lang="scss"></style>
