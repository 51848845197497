<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("user.admin.list.displayTitle") }}</span>
    </template>

    <template #default>
      <custom-crud-list v-bind="listConfiguration" @crud-list:items-updated="playground">
        <!--
        <template #cell-createdAt="{ item }">
          <span>{{ translateDate(item.createdAt, "dateWithTime") }}</span>
        </template>
     -->

        <template #cell-state="{ item }">
          <span v-if="item.state === 1"><icon icon="check" theme="success" /></span>
          <span v-if="item.state === 0"><icon icon="x" theme="error" /></span>
          <span v-if="item.state !== 1 && item.state !== 0">-</span>
        </template>
      </custom-crud-list>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";

export default {
  components: { CustomCrudList },
  layout: "adminDashboard",
  middleware: {
    requireSuperAdmin: {},
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "user.admin.list.pageTitle",
  data: function () {
    return {
      order: false,
      userForm: false,
      listConfiguration: {
        subject: "user",
        listSrc: "entity/user",
        initialOrdering: { key: "createdAt", direction: "desc" },
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: true, //bool, default false
        enableMassActions: true,
        editRoutePrefix: "admin-",
        editRoute: (item) => {
          this.$router.push({
            name: "entity-edit",
            params: {
              entityType: "user",
              entityId: item.id,
            },
          });
        },
        createRoute: (item) => {
          this.$router.push({
            name: "entity-create",
            params: {
              entityType: "user",
            },
          });
        },
        deleteRoutePrefix: "ecommerce/",
        additionalListData: {
          simplified: false,
          translate: false,
        },
        fields: {
          // these are the table columns
          state: {
            itemKey: "state",
            label: "user.admin.list.fields.state",
            filterable: {
              name: "state",
              type: "select2",
              placeholder: "user.admin.list.fields.state",
              translateList: true,
              list: [
                { value: 0, label: "user.admin.list.statuses.unpublished" },
                { value: 1, label: "user.admin.list.statuses.published" },
              ],
            }, // boolean, or object
            quickEdit: {
              type: "binaryInteger",
            },
            orderable: true,
          },
          createdAt: {
            itemKey: "createdAt",
            label: "user.admin.list.fields.createdAt",
          },
          fullName: {
            itemKey: "fullName",
            label: "user.admin.list.fields.fullName",
            orderable: true,
            filterable: true,
          },
          email: {
            itemKey: "email",
            label: "user.admin.list.fields.email",
            orderable: true,
            filterable: true,
          },
          phoneNumber: {
            itemKey: "phoneNumber",
            label: "user.admin.list.fields.phoneNumber",
            orderable: false,
            filterable: true,
          },
        },
        actions: {
          show: false,
          edit: true,
          delete: false,
          custom: {
            activate: {
              //tooltip
              tooltip: { content: "user.admin.list.actions.activate.label" },
              icon: "shield-check",
              theme: "success",
              show: (item) => {
                return item.state !== 1;
              },
              //text
              handler: async ({ item }) => {
                if (!item || !item.id) {
                  this.$s.ui.notification(
                    "user.admin.list.actions.activate.errorNotification",
                    "error"
                  );
                  return false;
                }

                let res = await this.asyncOps.asyncCall(
                  `user/${item.id}/activate`,
                  {},
                  { method: "patch" }
                );

                if (res.hasError) {
                  this.$s.ui.notification("core.genericError", "error");
                  return false;
                }

                item.state = 1;
                this.$s.ui.notification(
                  "user.admin.list.actions.activate.successNotification",
                  "success"
                );
              },
            },
            ban: {
              //tooltip
              tooltip: { content: "user.admin.list.actions.ban.label" },
              icon: "shield-x",
              theme: "error",
              show: (item) => {
                return item.state !== 0;
              },
              handler: async ({ item }) => {
                if (!item || !item.id) {
                  this.$s.ui.notification(
                    "user.admin.list.actions.ban.errorNotification",
                    "error"
                  );
                  return false;
                }

                this.$s.ui.modal
                  .confirm("user.admin.list.actions.ban.confirmText")
                  .then(async () => {
                    let res = await this.asyncOps.asyncCall(
                      `user/${item.id}/ban`,
                      {},
                      { method: "patch" }
                    );

                    if (res.hasError) {
                      this.$s.ui.notification("core.genericError", "error");
                      return false;
                    }

                    item.state = 0;
                    this.$s.ui.notification(
                      "user.admin.list.actions.ban.successNotification",
                      "success"
                    );
                  })
                  .catch((e) => {
                    this.$s.ui.notification("core.actionCanceled", "info");
                  });
              },
            },
            loginAsUser: {
              //tooltip
              tooltip: { content: "user.admin.list.actions.loginAsUser.label" },
              icon: "arrow-login-inline-end",
              theme: "inverse",
              //text
              handler: async ({ item }) => {
                if (!item || !item.id) {
                  this.$s.ui.notification(
                    "user.admin.list.actions.loginAsUser.errorNotification",
                    "error"
                  );
                  return false;
                }

                let res = await this.asyncOps.asyncCall(
                  `token/refresh/user/${item.id}`,
                  {},
                  { method: "post" }
                );

                if (res.hasError) {
                  this.$s.ui.notification("core.genericError", "error");
                  return false;
                }

                this.$store.commit("user/setToken", res.data.jwt);
                this.$store.commit("user/setRefreshToken", res.data.refreshToken);

                this.$store.commit(
                  "user/setRefreshTokenExpires",
                  res.data.refreshTokenExpires
                );

                let loginResult = await this.$store.dispatch("user/updateUserProfile");

                if (loginResult === false) {
                  this.$saffron.ui.notification(
                    this.safeTranslate("user.admin.list.actions.loginAsUser.loginFailErrorAndLostAccess"),
                    "danger"
                  );
                  this.$store.commit("user/invalidateAuth");
                  this.$store.commit("user/clearProfile");
                  this.$router.push('/');
                  return false;
                }

                this.$s.ui.notification(
                  "user.admin.list.actions.loginAsUser.successNotification",
                  "success"
                );
                this.$router.push('/');
              },
            },
          },
        },
      },
      asyncData: {
        userForm: "entity/user/update",
      },
    };
  },
  computed: {
    buyerInformationList() {
      const res = [];
      //  console.log(this.userForm.fields);
      Object.entries(this.userForm.fields).forEach(([key, field]) => {
        let label;

        if (field.placeholder) {
          label = field.placeholder;
        }
        if (field.label) {
          label = field.label;
        }
        res.push({ label: label, value: this?.order?.Buyer[key] });
      });
      return res;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
