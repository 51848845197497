<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("admin.acl.dashboardTitle") }}</span>
    </template>

    <template #default>
      <spinner
        size="2"
        text="core.loading"
        :show="asyncStatus.asyncDataLoading"></spinner>
      <div class="flex flex-between gap-xl">
        <block
          class="max-width-500 width-500 padding-m-horizontal padding-s-vertical bg-gray-1">
          <base-form
            v-model="aclFormModel"
            method="get"
            class=""
            action="acl/can-access"
            :config="aclFormConfig"
            @form:submitSuccess="handleAclCheckSuccess"></base-form>
          <div class="results padding-m-vertical margin-m-top bg-gray-1">
            <strong
              >{{ translate("admin.acl.useCaseForm.resultsTitle") }} </strong
            ><br />
            <span v-if="!aclCheckResult">{{
              translate("admin.acl.useCaseForm.noResultExplain")
            }}</span>
            <span v-else>
              <span
                v-if="aclCheckResult.can && aclCheckResult.implementsAcl"
                class="c-success"
                >{{ translate("admin.acl.useCaseForm.resultTrue") }}</span
              >
              <span
                v-if="!aclCheckResult.can && aclCheckResult.implementsAcl"
                class="c-error"
                >{{ translate("admin.acl.useCaseForm.resultFalse") }}</span
              >

              <br />
              <span v-if="!aclCheckResult.implementsAcl" class="c-warning">
                *
                {{
                  translate("admin.acl.useCaseForm.noAclImplementationExplain")
                }}</span
              >
            </span>
          </div>
        </block>

        <div
          class="use-cases width-expand animate__fadeIn"
          direction="ltr"
          dir="ltr"
          style="direction: ltr">
          <use-case-acl-tree :tree="{ children: groupedUseCases }">
            <!-- this will not work because of nesting, pass a renderer component instead
            <template #leaf="{leaf}">
              <block>
                leaf template<br>
                {{leaf}}
              </block>
            </template>-->
          </use-case-acl-tree>
        </div>
      </div>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import UseCaseAclTree from "@/client/components/admin/UseCaseAclTree";
import { computed } from "vue";

export default {
  components: {
    UseCaseAclTree,
  },
  layout: "adminDashboard",
  middleware: {
    requireSuperAdmin: {},
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.acl.dashboardBrowserTitle",
  data: function () {
    return {
      useCases: {},
      aclFormModel: {},
      aclCheckResult: null,
      aclFormConfig: {
        fields: {
          useCaseName: {
            name: "useCaseName",
            type: "select2",
            label: "admin.acl.useCaseForm.useCaseFieldLabel",
            list: computed(() => {
              let result = [];
              for (const [name, conf] of Object.entries(this.useCases)) {
                result.push({
                  value: name,
                  label: name.split(".").join("\\"),
                });
              }
              return result;
            }),
            validation: {
              required: {},
            },
          },
          userGroups: {
            name: "userGroups",
            type: "mappedSelect2",
            multiple: true,
            label: "admin.acl.useCaseForm.userGroupsFieldLabel",
            asyncListSource: "entity/userGroup",
            valueKey: "alias",
            labelKey: "name",
            asyncListData: {
              simplified: true,
            },
            validation: {
              required: {},
            },
          },
        },
      },
      asyncData: {
        useCases: "use-case/list/acl",
      },
    };
  },
  computed: {
    groupedUseCases() {
      let result = {};
      for (const [key, val] of Object.entries(this.useCases)) {
        let segments = key.split(".");
        let target = result;
        let len = segments.length;
        let i = 0;

        segments.forEach((segment) => {
          i++;

          if (!target[segment]) {
            target[segment] = {
              name: segment,
              children: {},
            };
          }

          // detect last
          if (i === len) {
            target[segment] = {
              name: segment,
              value: val,
            };
          }

          target = target[segment].children;
        });
      }
      console.log("tree", result);
      return result;
    },
  },
  methods: {
    handleAclCheckSuccess(e) {
      this.aclCheckResult = e.result.data;
    },
    getReadableKeyDisplay(key) {
      let segments = key.split(".");

      return segments.join(" \\ ");
    },
  },
};
</script>

<style scoped lang="scss"></style>
