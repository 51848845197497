<template>
  <div class="checkout">
    <div v-if="$store.getters['user/isLoggedIn']">
      <div v-if="!isComplete" class="">
        <block class="checkout-main width-expand">
          <div class="offline-checkout-explain margin-2xl-bottom">
            <h3>
              {{ translateTitleCase("ecommerce.checkout.index.offlineCheckoutTitle") }}
            </h3>
            <div class="clarification inline-block">
              <icon-text icon-align="top" icon="warning-circle">{{
                translateUcFirst("ecommerce.checkout.index.offlineCheckoutExplain")
              }}</icon-text>
            </div>
          </div>

          <div
            class="order-area relative flex flex-stretch gap-max flex-between flex-break-center width-expand margin-2xl-bottom">
            <div class="order-actions width-expand flex-column gap-xl">
              <card
                class="margin-xl-bottom"
                title="ecommerce.checkout.index.userDetailsTitle">
                <div>
                  <simple-value-list class="" :list="buyerDetailsList">
                    <template #item="{ item }">
                      <span
                        class="svl__label text-bold block min-width-200 block margin-m-bottom">
                        {{ translate(item.label) }}:
                      </span>
                      <span class="svl__value">
                        <slot name="value" v-bind="{ item }">
                          <slot :name="item.key" v-bind="{ item }">
                            <span>{{ item.value }}</span>
                          </slot>
                        </slot>
                      </span>
                    </template>
                  </simple-value-list>
                </div>
              </card>
              <card
                lass="margin-xl-bottom"
                title="ecommerce.checkout.index.sellerDetailsTitle">
                <div>
                  <simple-value-list class="" :list="sellerDetailsList">
                    <template #item="{ item }">
                      <span
                        class="svl__label text-bold block min-width-200 block margin-m-bottom">
                        {{ translate(item.label) }}:
                      </span>
                      <span class="svl__value">
                        <slot name="value" v-bind="{ item }">
                          <slot :name="item.key" v-bind="{ item }">
                            <span>{{ item.value }}</span>
                          </slot>
                        </slot>
                      </span>
                    </template>
                  </simple-value-list>
                </div>
              </card>

              <card
                lass="margin-xl-bottom"
                title="Payment details">
                <p>At this time, payment for all order is done via bank transfer. The bank account details are lister here, and will appear again after the order is placed.</p>

                <p>
                  Please complete the transaction within 7 days to claim your deal.
                </p>
                <panel  class="margin-xl-top">
                  <h3>Your Deal</h3>
                  <cart class="max-width-400"
                    :cart="cart"
                        :read-only="true"
                        :show-header="false"
                        :show-action-bar="false"></cart>
                  <hr class="margin-xl-vertical">
                  <h3 class="margin-remove">Transfer Details</h3>
                  <p class="">
                    <span>JustOpp ltd.</span><br>
                    <span>Hapoalim bank (12) - Galim branch (584),</span><br>
                    <span>Account nr. 164167</span>
                  </p>
                </panel>
              </card>
            </div>
            <div class="cart-container relative max-width-400" style="">
              <div class="cart" style="">
                <cart :read-only="true" :cart="cart">
                  <template #cart-item-extra="{ item, product }">
                    <div class="text-s flex-column width-expand gap-xs">
                      <!--<strong>{{translateNumber(item.quantity)}} units</strong>-->
                      <span>{{ product.code }}, {{ product.color }}</span>
                      <span>{{
                        translateUcFirst("ecommerce.cart.fromVendor", {
                          vendorName: product.Vendor.name,
                        })
                      }}</span>
                    </div>
                  </template>

                  <template #summary-action>
                    <block class="flex gap-m flex-center width-expand flex-wrap">
                      <!--
                       <form-button icon="cart" text="Review Cart" theme="bg"></form-button> -->
                      <form-button
                        theme="success"
                        icon="check"
                        text="justopp.checkout.confirmOrderCta"
                        @click="createOrder"></form-button>
                    </block>
                  </template>
                </cart>
              </div>
            </div>
          </div>
          <div class="margin-xl-bottom">
            <div class="flex-center flex-middle flex-column width-expand margin-auto">
              <form-button
                size="l"
                full-width="true"
                theme="success"
                icon="check"
                text="justopp.checkout.confirmOrderCta"
                @click="createOrder"></form-button>
              <icon-text
                icon="lock"
                :icon-size="2"
                icon-align="top"
                gap="m"
                class="text-s margin-l-top">
                {{ translateUcFirst("ecommerce.checkout.index.offlineOrderCtaExplain") }}
              </icon-text>
            </div>
          </div>
        </block>
        <hr class="margin-max-vertical" />
      </div>

      <div v-if="isComplete" class="order-complete-ui">
        <card class="margin-2xl-bottom">
          <template #title>
            <icon-text class="c-success margin-l-bottom" icon="shield-check">{{
              translateTitleCase("justopp.checkout.orderCompleteTitle")
            }}</icon-text>
          </template>
          <template #sub-title>
            <div class="cart-subtitle-content">
              <p>Dear Partner,</p>
              <p>
                Thank you for purchasing this item. We will contact you ASAP in order to
                finalize payment and shipment.
              </p>
              <p>
                We appreciate your business with us and assure you that we will guarantee
                the best quality and service you can find.
              </p>
              <p>
                If you have any questions or issues, please feel free to contact us on :
                <a href="mailto:nfo@justopp.com">info@justopp.com</a>.
              </p>
              <p>Thank you,</p>
              <p>The JustOpp Team</p>


            </div>
          </template>
          <template #default>
            <div class="order-info">
              <hr class="margin-xl-vertical" />


              <h3 class="margin-remove">Transfer Details</h3>
              <span>{{ translateUcFirst("justopp.checkout.order.yourOrderId") }}: </span>
              <strong v-if="order">{{ order?.id }}</strong><br>
              <p class="text-bold margin-xl-top">
                Please complete the transaction within 7 days to claim your deal.
              </p>
              <p class="text-l margin-m-top">
                <span>JustOpp ltd.</span><br>
                <span>Hapoalim bank (12) - Galim branch (584),</span><br>
                <span>Account nr. 164167</span>
              </p>
            </div>
          </template>
        </card>

        <h3 class="margin-max-top margin-m-bottom">
          {{ translateTitleCase("justopp.checkout.order.yourPurchase") }}:
        </h3>

        <cart v-if="order" class="margin-2xl-bottom" :cart="cart" :read-only="true">
          <template #cart-item-extra="{ item, product }">
            <div class="text-s flex-column width-expand gap-xs">
              <!--<strong>{{translateNumber(item.quantity)}} units</strong>-->
              <span>{{ product.code }}, {{ product.color }}</span>
              <span>{{
                translateUcFirst("ecommerce.cart.fromVendor", {
                  vendorName: product.Vendor.name,
                })
              }}</span>
            </div>
          </template>

          <template #summary-action>
            <block class="flex gap-m flex-center width-expand flex-wrap">
              <!--
               <form-button icon="cart" text="Review Cart" theme="bg"></form-button> -->
              <form-button
                icon="lock"
                :disabled="true"
                text="justopp.checkout.yourDealIsReserved"
                theme="success"></form-button>
            </block>
          </template>
        </cart>
        <div class="button-container flex-middle flex-center">
          <form-button
            icon="check"
            theme="success"
            text="core.backHome"
            @click="$router.push({ path: '/' })"></form-button>
        </div>
      </div>
    </div>
    <div v-else>
      <card title="justopp.checkout.requireLoginTitle">
        <p>
          {{ translateUcFirst("justopp.checkout.requireLoginExplain") }}
        </p>
        <div class="flex-start margin-2xl-top">
          <div class="flex flex-middle flex-center gap-l">
            <form-button
              icon="arrow-login-inline-end"
              text="login"
              @click="redirectToLogin()"></form-button>
            <span>OR</span>
            <form-button
              icon="user"
              text="Sign Up"
              @click="redirectToRegister()"></form-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useCarts from "@/client/extensions/composition/useCarts.js";
import Cart from "@/client/components/ecommerce/Cart.vue";
import { computed } from "vue";

export default {
  components: { Cart },
  layout: {
    component: "default",
    params: {
      title: "ecommerce.checkout.index.pageTitle",
      subTitle: "ecommerce.checkout.index.pageSubtitle",
    },
  },
  props: {
    cartUuid: {
      type: [String],
      default: undefined,
    },
  },
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);

    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data: function () {
    return {
      isComplete: false,
      cart: false,
      order: false,
      buyerDetailsList: [
        {
          label: "justopp.checkout.userDetails.name",
          value: this.$store.getters["user/profile"].fullName,
        },
        {
          label: "justopp.checkout.userDetails.companyEmail",
          value: this.$store.getters["user/profile"].personalEmail,
        },
        {
          label: "justopp.checkout.userDetails.companyPhone",
          value: this.$store.getters["user/profile"].personalPhoneNumber,
        },
        {
          label: "justopp.checkout.userDetails.companyId",
          value: this.$store.getters["user/profile"].companyId,
        },
      ],
      sellerDetailsList: [
        {
          label: "justopp.checkout.sellerDetails.vendorName",
          value: computed(() => {
            if (!this.cart || !this.cart?.cartItems[0]) {
              return "";
            }

            return this.cart.cartItems[0].Product.Vendor.name;
          }),
        },
        {
          label: "justopp.checkout.sellerDetails.shippingMethod",
          value: computed(() => {
            if (!this.cart || !this.cart?.cartItems[0]) {
              return "";
            }
            let methodId =
              this.cart.cartItems[0].Product.JustoppDealProductType.deliveryMethod;
            let methodString = "justopp.justoppDealProductType.deliveryMethod" + methodId;
            return this.translate(methodString);
          }),
        },
        {
          label: "justopp.checkout.sellerDetails.warehouseLocation",
          value: computed(() => {
            if (!this.cart || !this.cart?.cartItems[0]) {
              return "";
            }
            let val =
              this.cart.cartItems[0].Product.JustoppDealProductType.originWarehouse;
            if (!val || val === "") {
              return this.translateUcFirst(
                "justopp.justoppDealProductType.originWarehouseUndefined"
              );
            }
            return this.cart.cartItems[0].Product.JustoppDealProductType.originWarehouse;
          }),
        },
      ],
    };
  },
  meta: "ecommerce.checkout.index.browserTitle",
  async created() {
    this.cart = await this.carts.getCart(this.cartUuid);
  },
  mounted() {},
  methods: {
    redirectToRegister() {
      this.$router.push({
        name: "user-register",
        query: { redirect: "store-checkout" },
      });
    },
    redirectToLogin() {
      this.$router.push({
        name: "user-login",
        query: { redirect: "store-checkout" },
      });
    },
    async clearCart() {
      await this.cart.empty();
      await this.cart.refresh();
      this.cart = await this.carts.getCart();
    },
    async updateCart(item) {
      // console.log("update item");
      // console.log(item);
      //  const otherItem = JSON.parse(JSON.stringify(item));
      //  otherItem.quantity = 80;
      //  await this.cart.addItem(otherItem);
      // console.log(otherItem);
    },
    async createOrder() {
      let profile = this.$store.getters["user/profile"];
      const orderData = {
        UserId: profile?.id,
        OwnerId: profile?.id,
        CartUuid: this.cart.uuid,
        buyer: profile ? profile : {},
        shipping: {
          method: "test",
          address: { todo: "address module states" },
        },
      };

      const doFakeOrderUi = () => {
        return new Promise((fulfil) => {
          this.$s.ui.globalSpinner.show(
            "justopp.checkout.order.offlineOrderLoadingText1"
          );
          let secondSpinnerTimeout = utilities.setClientTimeout(() => {
            this.$s.ui.globalSpinner.show(
              "justopp.checkout.order.offlineOrderLoadingText2"
            );
          }, 3000);
          let finishTimeout = utilities.setClientTimeout(() => {
            this.$s.ui.globalSpinner.hide();
            fulfil();
          }, 6000);
        });
      };

      this.$s.ui.globalSpinner.show();

      const result = await this.asyncOps.asyncCall("ecommerce/order", orderData, {
        method: "post",
      });

      if (result.hasError) {
        this.$s.ui.globalSpinner.hide();
        this.$s.ui.notification(
          "justopp.checkout.order.orderCreationFailedNotification",
          "error"
        );
        return;
      }

      this.order = result.data.item;

      await doFakeOrderUi();
      let newCart = await this.carts.createNewDefaultCart();
      // this.cart = newCart;
      console.log("id", this.order.id);
      this.isComplete = true;
      //  await this.cart.empty();
      // let newCart = await this.carts.createNewDefaultCart();
      // this.cart = newCart;
      // this.carts.forgetCart(newCart);
    },
  },
};
</script>

<style scoped lang="scss">
.offline-checkout-explain {
}
.offline-checkout-explain {
  width: 100%;
  flex: 100%;
  margin-inline: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
  }
}
.order-area {
  width: 100%;
  flex: 100%;
  margin-inline: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
    height: auto;
  }
}
.cart-container {
  max-width: 400px;

  @media (max-width: 1024px) {
    max-width: 500px;
    height: auto;
  }

  .cart {
    width: 360px;
    position: sticky;
    top: 80px;
    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      max-width: 100%;
      width: 500px;
    }
  }
}
</style>
