<template>
  <div class="vendor-page">
    <skeleton :content-ready="!!vendor">
      <div class="vendor-page-ready">
        <div class="vendor-hero"></div>
        <div v-if="vendor" class="vendor-main-content">
          <div class="main-col">
            <div class="user-avatar user-avatar--xl margin-xl-bottom">
              <!--user-avatar__image--no-image-->
              <div
                class="user-avatar__image"
                :style="`background-image:url('${vendor.logoImageUrl}')`">
                <span class="user-avatar__image-letter"> L </span>
              </div>
              <div class="user-avatar__name">
                <span>{{ t(vendor.name) }}</span>
              </div>
            </div>
            <div class="about margin-2xl-bottom">
              <h2 class="h4">
                {{ t("ecommerce.vendor.aboutTitle", { vendorName: vendor.name }) }}
              </h2>
              <content-blocks :content="vendor.about"></content-blocks>
            </div>
            <div class="products">
              <h3>{{ t("ecommerce.vendor.keyProductsTitle") }}</h3>
              <div
                class="products-container flex-between flex-wrap flex-stretch gap-xl margin-l-bottom">
                <div v-for="i of 9" class="product border bg-gray-1">
                  <span>product placeholder</span>
                </div>
              </div>
              <div class="flex-middle flex-center">
                <form-button
                  theme="lead"
                  text="ecommerce.products.showMore"></form-button>
              </div>
            </div>
          </div>
          <div class="side-col">
            <h3>{{ t("ecommerce.reviews.reviews") }}</h3>
            <div class="reviews">
              <div
                v-for="i in 3"
                class="review border padding margin-l-bottom"
                style="width: 100%; min-height: 150px">
                REVIEW
              </div>
            </div>
          </div>
        </div>
      </div>
    </skeleton>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import { computed } from "vue";
export default {
  layout: "default",
  props: {
    id: {
      type: [String, Number],
      default: false,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      _vendor: {
        heroImageUrl: "https://placeimg.com/1000/480/any",
        logoImageUrl: "https://placeimg.com/200/200/tech/sepia",
        name: "Le-Vendor",
        about: {
          time: 1673267196810,
          blocks: [
            {
              id: "osyXagozEH",
              type: "paragraph",
              data: {
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
              },
            },
          ],
          version: "2.26.4",
        },
        reviews: [
          {
            user: {
              imageUrl: "https://placeimg.com/200/200/people",
              name: "Mister m",
            },
            vendorScore: 8,
            productScore: 9,
            text: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
            title: `Lorem Ipsum has been`,
            media: [
              {
                url: "https://placeimg.com/1200/1200/tech",
                thumbUrl: "https://placeimg.com/200/200/tech",
              },
            ],
          },
          {
            user: {
              imageUrl: "https://placeimg.com/200/200/people",
              name: "Miss L",
            },
            vendorScore: 5,
            productScore: 8,
            text: `is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`,
            title: `Lorem Ipsum has been`,
            media: [
              {
                url: "https://placeimg.com/1200/1200/tech",
                thumbUrl: "https://placeimg.com/200/200/tech",
              },
            ],
          },
        ],
        products: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
      },
      vendor: false,
      asyncData: {
        vendor: {
          target: computed(() => "ecommerce/vendor/" + this.id),
          options: {
            dataMutator: (data) => data.item,
          },
        },
      },
    };
  },
  meta: "ecommerce.vendor.pageTitle",
};
</script>

<style scoped lang="scss">
.vendor-page {
}

.hero {
}

.user-avatar {
  display: flex;
  align-items: center;
  gap: var(--margin-m);

  &__image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 35px;
    width: 35px;
    flex: 0 0 35px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-xl);
    background-color: var(--c-gray-1);
    border: 1px solid var(--c-gray-2);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1) inset;
    color: transparent;

    &--no-image {
      background-image: none !important;

      .user-avatar__image-letter {
        display: flex;
      }
    }

    &__image-letter {
      display: none;
      color: var(--c-alt);
      font-weight: bold;
    }
  }

  &__name {
    font-weight: 400;
    font-size: var(--font-m);
    margin: 0;
    padding: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    span {
      margin: 0;
    }
  }

  &--large,
  &--l {
    .user-avatar__image {
      height: 55px;
      width: 55px;
      flex: 0 0 55px;
      font-size: var(--font-2xl);
    }

    .user-avatar__name {
      font-size: var(--font-xl);
      font-weight: 600;
    }
  }

  &--xl {
    .user-avatar__image {
      height: 75px;
      width: 75px;
      flex: 0 0 75px;
      font-size: calc(var(--font-2xl) * 1.2);
    }

    .user-avatar__name {
      font-size: calc(var(--font-2xl) * 1.2);
      font-weight: 600;
    }
  }
}

.vendor-main-content {
  display: flex;
  gap: var(--margin-max);
  align-items: stretch;
  justify-content: space-between;
}

.side-col {
  flex: 0 0 300px;
}

.product {
  height: 300px;
  width: 240px;
}
</style>
