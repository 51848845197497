/**
 * When a route meta has requiresGuest, will redirect logged in users based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresGuestRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresGuestRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "saffronBus",
    handler: async (to, from, context) => {
      await utilities.waitForUser(router.app.store);

      if (router.app.store.getters["user/isLoggedIn"]) {
        return true;
      }

      let redirectArg = router.resolve({
        name: to.name,
        params: to.params,
      });

      const redirect = {
        name: "user-login",
        query: { redirectUrl: redirectArg.fullPath },
      };

      if (config.router.useI18n) {
        redirect.params = { localeSlug: router.app.store.getters["locale/slug"] };
      }

      router.push(redirect);
      return false;
    },
  };
};
