<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">
        {{ translateTitleCase("userDashboard.vendor.profile.pageTitle") }}
      </h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.vendor.profile.introText") }}
      </p>

      <crud-edit
        subject="vendor"
        :item-id="vendorId"
        :form-source="'entity/vendor/' + vendorId + '/update'"
        :submit-url="'ecommerce/vendor/' + vendorId"
        route-prefix="ecommerce/" />
    </template>
  </layout-user-dashboard>
</template>

<script>
import CrudEdit from "@/client/components/crud/customV1/Edit.vue";

export default {
  components: {
    CrudEdit,
  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.vendor.profile.pageTitle",
      subTitle: "userDashboard.vendor.profile.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
    },
  },
  middleware: {
    requireUser: {},
    requireVendor: {},
  },
  data: function () {
    return {};
  },
  computed: {
    vendorId() {
      return this.$store.getters["user/profile"]?.Vendor?.id;
    },
  },
  meta: "userDashboard.vendor.profile.browserTitle",
};
</script>

<style scoped lang="scss"></style>
