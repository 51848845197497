<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">
        {{ translateTitleCase("userDashboard.vendor.dashboard.pageTitle") }}
      </h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.vendor.dashboard.introText") }}
      </p>
      <p>
        //
      </p>
    </template>
  </layout-user-dashboard>
</template>

<script>
export default {
  layout: {
    component: "default",
    params: {
      title: "userDashboard.vendor.dashboard.pageTitle",
      subTitle: "userDashboard.vendor.dashboard.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove"
    },
  },
  middleware: {
    requireUser: {},
    requireVendor: {},
  },
  data: function () {
    return {};
  },
  meta: "userDashboard.vendor.dashboard.browserTitle",
};
</script>

<style scoped lang="scss"></style>
