<template>
  <div class="container margin-2xl-top" dir="ltr">
    <card :auto-translate="false" title="playground" badge-text="testing area">
      <media-manager></media-manager>

      <block class="min-height">
        <form-input v-model="files" type="file" name="file"></form-input>
        <form-button
          :auto-translate="false"
          class="margin-s-top"
          :loading="asyncStatus.loading"
          @click="uploadFile()"
          >Upload file
        </form-button>
        <!--
        <p class="block">// content here</p>
        <base-form
          v-model="pgFormData"
          method="post"
          action="files"
          :config="playgroundFormConfig"
          @form:submitSuccess="pgFormSuccessHandler"></base-form>
        <base-form
          v-model="pgForm2Data"
          method="delete"
          :action="pgForm2ComputedAction"
          :config="playgroundForm2Config"
          @form:submitSuccess="pgForm2SuccessHandler"></base-form>

        -->
        <form-button
          :auto-translate="false"
          class="margin-xl-top"
          :loading="asyncStatus.loading"
          @click="playground()"
          >TEST!
        </form-button>
        <form-button
          :auto-translate="false"
          class="margin-xl-top"
          :loading="asyncStatus.loading"
          @click="playgroundServer()"
          >TEST server!
        </form-button>

        <form-button
          :auto-translate="false"
          class="margin-xl-top"
          :loading="asyncStatus.loading"
          @click="playgroundCustom()"
          >CUSTOM server!
        </form-button>

        <form-button
          :auto-translate="false"
          class="margin-xl-top"
          @click="$store.commit('user/logout')"
          >LOGOUT
        </form-button>
      </block>

      <template #footer> Footer</template>
    </card>
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useMediaManager from "@/client/extensions/composition/useMediaManager";

export default {
  name: "PlaygroundPage",
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    let mediaManager = useMediaManager(props, {});
    return { asyncOps, asyncOpsReady, asyncStatus, mediaManager };
  },
  data: function () {
    return {
      files: null,
      pgFormData: {
        accountUUID: "0829a46e-ac41-41e0-ac64-2ac38f58ec66",
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMDgyOWE0NmUtYWM0MS00MWUwLWFjNjQtMmFjMzhmNThlYzY2IiwiZmlsZXMiOlsiMS5qcGciXSwiYWN0aW9ucyI6WyJyZWFkIiwidXBkYXRlIiwiZGVsZXRlIiwiY3JlYXRlIl0sImlhdCI6MTY3MDQxMTk3OCwiZXhwIjoxNjcwNDE1NTc4LCJhdWQiOiJzYWZmcm9uLWZpbGVzIn0.lOYJ7nU2eUNagdzgI9QFdfAnHscfkWHCS6LQjT2xIfw",
      },
      pgForm2Data: {
        accountUUID: "0829a46e-ac41-41e0-ac64-2ac38f58ec66",
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiMDgyOWE0NmUtYWM0MS00MWUwLWFjNjQtMmFjMzhmNThlYzY2IiwiZmlsZXMiOlsiMS5qcGciXSwiYWN0aW9ucyI6WyJyZWFkIiwidXBkYXRlIiwiZGVsZXRlIiwiY3JlYXRlIl0sImlhdCI6MTY3MDQxMTk3OCwiZXhwIjoxNjcwNDE1NTc4LCJhdWQiOiJzYWZmcm9uLWZpbGVzIn0.lOYJ7nU2eUNagdzgI9QFdfAnHscfkWHCS6LQjT2xIfw",
        file: "33fc4e09-7e94-4eaa-9325-5ba2b99f8f2e.jpeg",
      },
      playgroundFormConfig: {
        fields: {
          name: {
            type: "text",
            name: "name",
            label: "core.form.name",
          },
          accountUUID: {
            type: "text",
            name: "accountUUID",
            label: "account uuid",
          },
          token: {
            type: "text",
            name: "token",
            label: "token",
          },
          file: {
            type: "file",
            name: "file",
            label: "file",
          },
        },
        formHandling: {
          requestAdapterOptions: {
            saffronAxios: {
              asFormData: true,
            },
          },
          preflight: [
            {
              type: "custom",
              options: {
                handler: async ({ next }) => {
                  next(true);
                  return true;
                },
              },
            },
            {
              type: "validation",
              options: {},
            },
            /*
             {
             type: "recaptcha",
             options: {},
             },
             */
          ],
        },
      },
      playgroundForm2Config: {
        fields: {
          name: {
            type: "text",
            name: "name",
            label: "core.form.name",
          },
          accountUUID: {
            type: "text",
            name: "accountUUID",
            label: "account uuid",
          },
          token: {
            type: "text",
            name: "token",
            label: "token",
          },
          file: {
            type: "text",
            name: "file",
            label: "file name",
          },
        },
        formHandling: {},
      },
    };
  },
  computed: {
    pgForm2ComputedAction() {
      let result = "files";
      if (this.pgForm2Data.accountUUID) {
        result = result + "/" + this.pgForm2Data.accountUUID;
      }

      if (this.pgForm2Data.file) {
        result = result + "/" + this.pgForm2Data.file;
      }
      console.log("computed result", result);
      return result;
    },
  },
  watch: {},
  mounted() {
    //  throw new Error('pg error');
  },
  created() {
    //    throw new Error('pg created error');
    //   this.ss('test.testA', 'genericValue!')
    //  console.log('after setter', this.sg('test.testA', 'DEFAULT VALUE'));
  },
  methods: {
    async playground() {},
    async playgroundServer() {
      let payload = {};
      payload = {};
      let result = await this.asyncOps.asyncCall(
        "playground/use-case",
        payload
      );

      console.log(result);

      if (result.isError) {
        console.log("request error", result.code, result.status.error);
      } else {
        console.log("request successful");
      }

      console.log("data", result.data);
    },
    async playgroundCustom() {
      let payload = {};
      console.log("run");

      let result = await this.asyncOps.asyncCall("use-case/list/acl", payload);

      //    let result = await this.asyncOps.asyncCall('acl/by-group', payload);

      console.log(result);

      if (result.isError) {
        console.log("request error", result.code, result.status.error);
      } else {
        console.log("request successful");
      }

      console.log("data", result.data);
    },
    pgFormSuccessHandler(result) {
      console.log("form result is success:", !!result.isError);
      console.log(result.resultData);
    },
    pgForm2SuccessHandler(result) {
      console.log("form 2 result:", !!result.isError);
      console.log(result.resultData);
    },
    async uploadFile() {
      let result;
      await this.performWithUi(async () => {
        return (result = await this.mediaManager.upload(this.files[0]));
      });
      /*
      await this.performWithUi(async () => {
        return (result = await this.mediaManager.rename(
          result.id,
          "test111.png"
        ));
      });

      const test = await this.performWithUi(async () => {
        return (result = await this.mediaManager.delete(result.id));
      });
*/

    },
  },
};
</script>

<style scoped lang="scss"></style>
