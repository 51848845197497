// overload component options data with meta move options.meta to options.methods[accessorKey], so that plugins can access them on instance
function assignDataAccessor(target) {
  if (!target || !target.meta) return true;

  if (!target.methods) target.methods = {};

  //config.metaDataAccessorKey
  target.methods[config.metaDataAccessorKey] = function () {
    return target.meta;
  };
}

/**
 * Assign meta configuration from the component options, to the component data.
 * This allows nicer api, so that meta config is not nested inside data, but as a separate entry
 * on the component options
 *
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "beforeResolve",
    handler: async (to) => {
      if (!to.matched) {
        return;
      }

      if (to.matched.length < 1) {
        return;
      }
      try {
        to.matched.forEach((match) => {
          if (match.components && match.components.default) {
            assignDataAccessor(match.components.default);
          }
        });
      } catch (e) {}
    },
  };
};
