// overload component options data with meta move options.meta to options.methods[accessorKey], so that plugins can access them on instance
function assignAccessor(target) {
  if (!target || !target.layout) return true;

  if (!target.methods) target.methods = {};

  //config.metaDataAccessorKey
  target.methods[config.layoutAccessorKey] = function () {
    return target.layout;
  };
}

/**
 * Assign layout accessor configuration from the component options, to the component data.
 * This allows nicer api, so that meta config is not nested inside data, but as a separate entry
 * on the component options
 *
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
  return {
    routerMethod: "beforeResolve",
    handler: async (to) => {
      try {
        assignAccessor(to.matched[to.matched.length - 1].components.default);
      } catch (e) {}
    },
  };
};
