<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'admin-product' }">
    <template #title>
      <span>{{ translateTitleCase("ecommerce.product.edit.displayTitle") }}</span>
    </template>

    <template #default>
      <block class="relative">
        <spinner v-if="!isFormReady" overlay="absolute"></spinner>
        <custom-crud-edit
          v-bind="editConfiguration"
          @item:updated="handleItemUpdated"></custom-crud-edit>
      </block>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudEdit from "@/client/components/crud/customV1/Edit.vue";
import useProducts from "@/client/extensions/composition/useProducts";
import { computed } from "vue";
import _ from "lodash";

export default {
  components: { CustomCrudEdit },
  layout: "adminDashboard",

  middleware: {
    requireSuperAdmin: {},
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    let { productForm } = useProducts(props);

    return { asyncOps, asyncOpsReady, asyncStatus, productForm };
  },
  meta: "admin.products.edit.pageTitle",
  data: function () {
    return {
      isFormReady: computed(() => this.productForm.isReady),
      initialFormLoaded: false,
      editConfiguration: {
        subject: "product",
        routePrefix: "ecommerce/",
        submitUrl: computed(() => {
          return "ecommerce/product/" + this.id;
        }), // default: shine/[name]
        duplicateUrl: "ecommerce/product/",
        duplicationSanitizer: async (data) => {
          data.name = data.name + " (copy)";
          data.types.forEach((type) => {
            delete type.id;
          });

          data.typeList.forEach((type) => {
            let key = _.upperFirst(type) + "ProductType";
            if (data[key]) {
              delete data[key].id;
            }
          });

          return data;
        },
        submitMethod: "patch", //default 'patch'
        cancelRedirect: { name: "admin-product" },
        successRedirect: { name: "admin-product" },
        itemId: this.id,
        itemMapper: async (data) => {
          return data;
        },
        formConfig: computed(() => this.productForm.config),
      },
      asyncData: {},
    };
  },
  computed: {},
  methods: {
    handleItemUpdated({ itemData, oldData }) {
      let targetTypes = Array.isArray(itemData.typeList) ? itemData.typeList : [];

      // if this is the first data that we get, update the form (foce)
      if (!this.initialFormLoaded) {
        this.initialFormLoaded = true;
        this.productForm.adjustTypes(targetTypes, true);
        return;
      }
      // if there is old data, which is change, update the form if required
      if (itemData?.typeList && oldData?.typeList) {
        this.productForm.adjustTypes(targetTypes);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
