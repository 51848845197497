<template>
  <layout-admin-dashboard-view :show-toolbar="false" :back-route="{ name: 'admin-dashboard' }">
    <template #title>
      <span>{{ translateTitleCase("ecommerce.products.displayTitle") }}</span>
    </template>

    <template #default>
      <custom-crud-list v-bind="listConfiguration">
        <template #cell-VendorId="{ item }">
          <span>{{ item.Vendor ? item.Vendor?.name : '-' }}</span>
        </template>
        <template #cell-introImage="{ item }">
          <img :src="item.introImage" style="max-height: 50px; width: auto; max-width: 200px" alt="" />
        </template>
        <template #cell-state="{ item }">
         <span v-if="item.state" class="c-success">
           <icon icon="check"></icon>
         </span>
          <span v-else class="c-error">
           <icon icon="x"></icon>
         </span>
        </template>
        <template #cell-featured="{ item }">
         <span v-if="item.featured" class="c-success">
           <icon icon="check"></icon>
         </span>
          <span v-else="item.featured" class="c-error">
           <icon icon="x"></icon>
         </span>
        </template>
      </custom-crud-list>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";
export default {
  components: { CustomCrudList },
  layout: "adminDashboard",

  middleware: {
    requireSuperAdmin: {},
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  meta: "admin.products.pageTitle",
  data: function () {
    return {
      listConfiguration: {
        subject: "product",
        listSrc: "ecommerce/product",
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: true, //bool, default false
        enableMassActions: true,
        editRoutePrefix: "admin-",
        createRoutePrefix: "admin-",
        deleteRoutePrefix: "ecommerce/",
        additionalListData: {
          simplified: false,
          translate: false,
        },
        fields: {
          // these are the table columns
          name: {
            itemKey: "name",
            label: "ecommerce.product.fields.name",
            filterable: true, // boolean, or object
            orderable: true,
          },
          VendorId: {
            itemKey: "VendorId",
            label: "ecommerce.product.fields.vendor",
            filterable: {
              name: "VendorId",
              type: "Select2",
              translateList: false,
              valueKey: "id",
              labelKey: "name",
              placeholder: "ecommerce.product.fields.vendorFilterPlaceholder",
              itemComponent: "iconItem",
              asyncListSource: "entity/Vendor",
              asyncListLive: false,
              textFilterAsSaffronListFilter: true,
              asyncDataTextFilterParamName: "title",
              multiple: false,
              asyncListData: {
                simplified: true,
              },
            }, // boolean, or object
          },
          introImage: {
            itemKey: "introImage",
            label: "ecommerce.product.fields.introImage",
          },
          state: {
            itemKey: "state",
            label: "core.entity.state",
            filterable: true, // boolean, or object
            orderable: true,
            quickEdit: {
              type: "binaryInteger",
            }
          },
          featured: {
            itemKey: "featured",
            label: "ecommerce.product.fields.featured",
            filterable: true, // boolean, or object
            orderable: true,
            quickEdit: {
              type: "binary",
            }
          },
        },
        actions: {
          show: false,
          edit: true,
          delete: true,
        },
      },
      asyncData: {},
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
