<template>
  <div class="container padding-2xl-vertical">
    <card title="Payments playground">
     <debug :data="gateways"></debug>

      <form-button @click="testServer">test setver</form-button>
      <br>
      <form-button @click="manualPayment">manual payment</form-button>
      <hr class="margin-l-vertical"/>
      <payment
        ref="payment-component"
        @payment:created="reportPaymentCreated"
        @payment:completed="reportPaymentCompleted"
        @payment:failed="reportPaymentFailed"
        class="payment border-thin-lead"
        gateway="offline" :amount="6000" currency="USD">
      </payment>
      <!--
      <iframe
        src="http://localhost:3001/api/payment/confirmation-iframe">
      </iframe>-->
    </card>
  </div>
</template>

<script>
import { ref, reactive, computed, watchEffect } from "vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import Payment from "@/client/components/payment/Payment.vue";
export default {
  components: {
    Payment,
  },
  props: {},
  setup(props) {
    let { asyncOps, asyncOpsReady } = asyncOperations(props);

    return { asyncOps, asyncOpsReady };
  },
  data: function () {
    return {
      gateways: false,
      asyncData: {
        gateways: 'payment/gateway'
      },
    };
  },
  watch: {
    async asyncOpsReady() {},
  },
  created() {
    if (utilities.isSSR()){
      return;
    }

   // window.addEventListener('message', this.handleIframeMessage);
  },
  methods: {
    handleIframeMessage(a,b) {
      console.log(a, b);
    },
    async manualPayment() {
      this.$refs['payment-component'].executePayment(10000,"USD")
    },
    async testServer() {
      let data ={};
      let result = await this.asyncOps.asyncCall('playground/use-case2',data, {method: 'get'});

      console.log('end');
      console.log(result);

    },

    async reportPaymentCompleted({ payment }){
    //  console.log('completed', payment);
    //  let uuid = payment.uuid;
     // let assignResult = await this.asyncOps.asyncCall(`payment/${uuid}/assign`, {
      //  payableType : "order",
      //  payableId: 247,
     // }, {method: "patch"});
    },
    reportPaymentCreated(eventData){},
    reportPaymentFailed(eventData){}

  },
};
</script>

<style scoped lang="scss"></style>
