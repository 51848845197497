<template>
  <div>
    <card
      class="margin-2xl-vertical"
      sub-title="The purchase is complete and your items will be on their way shortly.">
      <template #title>
        <icon-text class="c-success" icon="shield-check">Order Complete</icon-text>
      </template>
    </card>
    <cart-display v-if="cart" :cart="cart" readonly />
  </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useCarts from "@/client/extensions/composition/useCarts.js";
import CartDisplay from "@/client/components/ecommerce/Cart.vue";

export default {
  components: { CartDisplay },
  layout: "default",
  props: {},
  setup(props) {
    const { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations();
    let carts = useCarts(props);

    return { asyncOps, asyncOpsReady, asyncStatus, carts };
  },
  data: function () {
    return {
      cart: false,
    };
  },
  computed: {},
  meta: "ecommerce.checkout.orderComplete.browserTitle",
  async created() {
    this.cart = await this.carts.getCart();
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
